// style.js

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Container for the custom tabs
  customTabsContainer: {
    display: 'flex',
    justifyContent: 'start',
    position: 'relative',
    alignItems: 'center',
    // borderBottom: `1px solid ${theme.palette.divider}`, // Use theme divider color
    padding: '10px 0',
    width: '100%',
  },

  // Style for each individual tab
  customTab: (theme) => ({
    cursor: 'pointer',
    padding: '10px 24px',
    margin: '0 5px',
    textTransform: 'uppercase',
    color: theme.textColor, // Use theme text color
    position: 'relative', // Position the tab relative to its container
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Use theme hover color
    },
  }),

  // Style for the active tab
  activeTab: {
    fontWeight: 'bold',
    color: theme.text, // Use theme primary color for active tab
  },

    // Style for the moving underline
    movingUnderline: {
      position: 'absolute',
      bottom: "7px",
      height: '3px',
      backgroundColor: "#f50057", // Use theme primary color for underline
      transition: 'all 0.4s ease-in-out',
    },

  // Optional: Override other tab-related styles based on your needs
}));

export default useStyles;
