
import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Input, InputAdornment, Button, Tabs, Tab } from '@material-ui/core';
import { useTheme } from "styled-components";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Refresh as RefreshIcon, Lock as LockIcon, Search as SearchIcon, Add as AddIcon, ContactlessOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { changeTab } from 'actions/challenge';
import useStyles from "./styles.js";

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from '../../../selectors/browser.js';
import { getParameters, isValidUrl } from 'helper/helper.js';
import CustomTabs from 'Components/CustomTabs/CustomTabs.js';
import { FormattedMessage } from 'react-intl';

const BrowserTab = (props) => {

    // const [getUser, setGetUser] = useState({}) 

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    // console.log(page, tabs, "page")

    const theme = useTheme();
    const classes = useStyles(theme);
    
    const [urlEdit, setUrlEdit] = useState('');
    
    const dispatch = useDispatch();
        
    // const classes = useStyles(theme);

    const handleUrlChange = (event) => {
        setUrlEdit(event.target.value);
    };

    const setPageUrl = () => {

        // Execute your search function or any other logic here
        let action = {};
        
        const urlValidity = isValidUrl(url);
        console.log(url, urlValidity, "url on browser");
        
        // check if the url is valid

        action = { type: 'UPDATE_PAGE', payload: "error" };
        
        if(url.includes("https://www.bestsocial.com/profile?uid=")){
            action = { type: 'UPDATE_PAGE', payload: "profile" };
        }
        else if(url.includes("https://www.bestsocial.com/profile/edit?uid=")){
            action = { type: 'UPDATE_PAGE', payload: "edit" };
        }
        else if(url == "https://www.bestsocial.com" || url == "https://www.bestsocial.com/" || url == "https://www.flopify.com" || url.includes("https://www.flopify.com?")){
            action = { type: 'UPDATE_PAGE', payload: "menu" };
        }
        else if(url.includes("bestsocial.com/users")){
            action = { type: 'UPDATE_PAGE', payload: "users" };
        }
        else if(url.includes("bestsocial.com/admin")){
            action = { type: 'UPDATE_PAGE', payload: "admin" };
        }
        else if(url.includes("flopify.com/product?slug=")){
            action = { type: 'UPDATE_PAGE', payload: "product" };
        }
        else if(url.includes("flopify.com/payment?slug=")){
            action = { type: 'UPDATE_PAGE', payload: "payment" };
        }
        else if(url.includes("flopify.com/thankyou")){
            action = { type: 'UPDATE_PAGE', payload: "thankyou" };
        } 
        else if(url.includes("/login")){
            action = {type: "UPDATE_PAGE", payload: "login"};
        }
        else if(url.includes("https://www.bank.")){

            if(url == "https://www.bank.com" || url == "https://www.bank.com/"){
                action = { type: 'UPDATE_PAGE', payload: "menu" };
            }
            else if(url.includes("/login")){
                action = { type: 'UPDATE_PAGE', payload: "login" };
            }
            else if(url.includes("/savings")){
                action = { type: 'UPDATE_PAGE', payload: "savings" };
            }
            else if(url.includes("/transactions")){
                action = { type: 'UPDATE_PAGE', payload: "transactions" };
            }
            else if(url.includes("/transaction")){
                action = { type: 'UPDATE_PAGE', payload: "transaction" };
            }
            // accessing mainly the scam pages:
            // https://www.bank.change-your-password.com
            // https://www.bank.verify-your-account.com
        }
        // console.log('Search executed:', url);

        
        dispatch(action);
        if(window.parent === window){
            console.log({state: action}, url, "action browser")
            document.getElementsByClassName("challenge-iframe")[0].contentWindow.postMessage({state: action}, "*");
        }  
    }

    useEffect(() => {
        if (!url) return;

        async function fetchParams() {
            const urlParams = await getParameters(url);
            // console.log(urlParams, "urlParams")
            dispatch({ type: 'UPDATE_PARAMS', payload: urlParams });
        }

        // // send the url to the iframe
        // if(window.parent === window){
        //     document.getElementsByClassName("challenge-iframe")[0].contentWindow.postMessage({state: { type: 'UPDATE_URL', payload: url }}, "*");
        // }

        setPageUrl();
        
        fetchParams();

        setUrlEdit(url);
    }, [url]);

    // if(url && urlEdit)
    // {
    //     setUrlEdit(url)
    //     // https://www.bestsocial.com/profile?uid=iAmAHacker' AND  1=2  UNION SELECT name, username, avatar FROM users WHERE username LIKE '%admin%' -- 
    
    
    
    //     const extractParams = async () => {
    //         const urlParams = await getParameters(url);
    //         dispatch({ type: 'UPDATE_PARAMS', payload: urlParams });
    //     };
    
    //     extractParams();
    
    //     setPageUrl();
    // }

    
    

    // if(page && undoHistory){

    //     let newUndoHistory = undoHistory;

    //     if(undoHistoryPointer < undoHistory.length - 1){
    //         newUndoHistory = newUndoHistory.splice(undoHistoryPointer + 1, newUndoHistory.length - undoHistoryPointer - 1);
    //     }

    //     // newUndoHistory.push(page);

    //     dispatch({ type: 'UPDATE_UNDO_HISTORY', payload: newUndoHistory });
    //     dispatch({ type: 'UPDATE_UNDO_HISTORY_POINTER', payload: undoHistory.length - 1 });
    // }

    const handleEnterPress = async (event) => {
        if (event.key === 'Enter') {
            dispatch({ type: 'UPDATE_URL', payload: urlEdit });
            // send this action to the iframe

            // console.log(window.parent === window)
            if(window.parent === window){
                document.getElementsByClassName("challenge-iframe")[0].contentWindow.postMessage({state: { type: 'UPDATE_URL', payload: urlEdit }}, "*");
            }  

            // setPageUrl();
        }
    };


    const handleTabChange = (event, newValue) => {
        // console.log(newValue, activeTab)
        // if (newValue === activeTab) return; // If the new tab is the same as the current tab, do nothing
        // setActiveTab(newValue);
        // You can perform additional actions when a tab is changed
    };

    const handleAddTab = () => {
    // You can implement the logic to add a new tab
    };

    const handleGoBack = () => {
        if(undoHistory[undoHistoryPointer - 1] === undefined) return;
        dispatch({ type: 'UPDATE_PAGE', payload: undoHistory[undoHistoryPointer - 1] });
        dispatch({ type: 'UPDATE_UNDO_HISTORY_POINTER', payload: undoHistoryPointer - 1 });
    }
    const handleGoForward = () => {
        if(undoHistory[undoHistoryPointer + 1] === undefined) return;
        dispatch({ type: 'UPDATE_PAGE', payload: undoHistory[undoHistoryPointer - 1] });
        dispatch({ type: 'UPDATE_UNDO_HISTORY_POINTER', payload: undoHistoryPointer - 1 });
    }
    const handleRefresh = () => {
        // refresh the iframe page

        document.getElementsByClassName("challenge-iframe")[0].contentDocument.location.reload(false);
    }
    const handleSearch = () => {
        dispatch({ type: 'UPDATE_URL', payload: urlEdit });
        // setPageUrl();
    }

    const handleChangeTab = (tab) => {

        if(!tab || !activeTab) return;
        if (tab === activeTab) return; // If the new tab is the same as the current tab, do nothing

        dispatch({ type: 'UPDATE_ACTIVE_TAB', payload: tab });

        // console.log("Browser changed step added", tab, activeTab);

        dispatch(changeTab({tab: tab, browser: selectedBrowser})).then(() => {
            console.log("Browser changed step added");
        });
    }

    return (
            <>
            <div className={`d-flex justify-content-space align-items-center w-100 ${classes.text}`}>
        {/* Back Button */}
        {/* <IconButton color="inherit" onClick={handleGoBack}>
            <ArrowBackIcon />
        </IconButton> */}

        {/* Forward Button */}
        {/* <IconButton color="inherit" onClick={handleGoForward}>
            <ArrowForwardIcon />
        </IconButton> */}

        {/* Refresh Button */}
        <IconButton color="inherit" onClick={handleRefresh}>
            <RefreshIcon />
        </IconButton>

        {/* Simulated Search Bar */}
        <div className={`browser-url-input ${classes.text}`} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Input
                variant="outlined"
                color="inherit"
                fullWidth
                value={urlEdit}
                className={`${classes.text}`}
                onChange={handleUrlChange}
                onKeyDown={handleEnterPress}
                startAdornment={
                    <InputAdornment position="start">
                        <LockIcon color="inherit" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <Button color="inherit" startIcon={<SearchIcon />} onClick={() => handleSearch()}>
                        <FormattedMessage id="search" defaultMessage="Search" ></FormattedMessage>
                        </Button>
                    </InputAdornment>
                }
            />
        </div>
    </div>
    {/* Tabs Row */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {activeTab && tabs && <>
            <CustomTabs
                browsers={tabs}
                selectedBrowser={activeTab}
                handleChangeBrowser={handleChangeTab}
            />
            
        </>}

            {/* Add Tab Button */}
            {/* <IconButton color="inherit" onClick={handleAddTab}>
                <AddIcon />
            </IconButton> */}
        </div>
            </>
    );
};

export default BrowserTab;