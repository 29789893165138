import React, { useEffect, useState } from 'react';
import { Container, Paper } from '@mui/material';

const Cookies = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}/cookies.html`)  // Adjust the path
      .then((response) => response.text())
      .then((data) => {
        console.log(data, "asdf akjl ")
        setHtmlContent(data);
      });
  }, []);

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </Paper>
    </Container>
  );
};

export default Cookies;
