import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  AppBar,
  Typography,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useStyles from "./styles.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AvatarIcon from "../Avatar/AvatarIcon/AvatarIcon.js";
import LogoutButton from "../Button/LogoutButton/LogoutButton.js";
import { AuthContext } from "../../actions/auth.js";
import { useSelector } from "react-redux";
import { DarkMode } from "../../contexts/DarkMode.js";
import * as Regular from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { SideMenuContext } from "../../contexts/SideMenuContext.js";
import LanguageSelector from "Components/LanguageSelector/LanguageSelector.js";
import { FormattedMessage } from "react-intl";
import { LangContext } from "contexts/LangProvider.js";

export const Navbar = (props) => {
  const { isOpen, setIsOpen } = props;
  const { isDarkMode, setIsDarkMode } = useContext(DarkMode);
  const { menuOpen, setMenuOpen } = useContext(SideMenuContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  const user = useSelector((state) => state.auth);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const {locale, setLocale} = useContext(LangContext);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const token = user?.token;
  }, [location]);

  const handleLanguageChange = (selectedLocale) => {
    setLocale(selectedLocale);  
    // store locale in local storage
    localStorage.setItem("locale", selectedLocale);

    // send the theme to the iframe

    var iframe = document.querySelector('iframe');
    if(iframe){
      iframe.contentWindow.postMessage({locale: selectedLocale}, '*');
    }

  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <div className={classes.drawer}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="HackMeDown" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to={window.location.hostname.includes("blog") ? process.env.REACT_APP_PUBLIC_URL : process.env.REACT_APP_BLOG_URL}>
          <ListItemText primary={<FormattedMessage id="home" defaultMessage="Home" />} />
        </ListItem>
        <ListItem button component={Link} to={process.env.REACT_APP_PUBLIC_URL + "/about"}>
          <ListItemText primary={<FormattedMessage id="about" defaultMessage="About" />} />
        </ListItem>
        <ListItem >
          <IconButton className="p-0" onClick={() => {setIsDarkMode(!isDarkMode); localStorage.setItem('darkMode', !isDarkMode);}}>
                <FontAwesomeIcon className={classes.darkModeSwitch} icon={isDarkMode ? Regular.faSun : Regular.faMoon} />
              </IconButton>
        </ListItem>
        <Divider />
        <ListItem>
          <LanguageSelector onLanguageChange={handleLanguageChange} />
        </ListItem>
        <ListItem>
            <a href='https://ko-fi.com/B0B41QX3H' target='_blank'>
              <img height='36' style={{border: '0px', height:'36px'}} src='https://storage.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
            </a> 
        </ListItem>
        {user ? (
          <>
            <ListItem button onClick={() => setMenuOpen(true)}>
              <AvatarIcon username={user?.result.username} avatar={user?.result.avatar} />
              <Typography variant="h6" className={`${classes.textColor} px-3`}>
                {user?.result.name.split(" ")[0]}
              </Typography>
            </ListItem>
            <ListItem>
            <LogoutButton />
            </ListItem>
          </>
        ) : (
          <ListItem className={{padding: "0.5rem"}} button component={Link} to={window.location.hostname.includes("blog") ? process.env.REACT_APP_PUBLIC_URL + "/login" : "login"}>
            <ListItemText primary={<FormattedMessage id="login" defaultMessage="Login" />} />
          </ListItem>
        )}
      </List>
    </div>
  );

  const changeMode = () => {
    setIsDarkMode(!isDarkMode); 
    localStorage.setItem('darkMode', !isDarkMode);
  }

  return (
    <AppBar className={classes.appBar} position="static" color="inherit">
      <Container maxWidth="lg" className={classes.navbarContainer}>
        <Toolbar className={classes.toolbar}>
          {isMobile ? (
            <>
              <Typography className={classes.mobileHeading} component={Link} to="/" variant="h5" align="center">
                HackMeDown
              </Typography>
              <IconButton edge="end" className={classes.textColor} color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                {drawerContent}
              </Drawer>
            </>
          ) : (
              <div className={classes.brandContainer}>
              <Typography className={classes.heading} component={Link} to="/" variant="h4" align="center">
                HackMeDown
              </Typography>
              <IconButton className="p-0" onClick={() => {changeMode()} }>
                <FontAwesomeIcon className={classes.darkModeSwitch} icon={isDarkMode ? Regular.faSun : Regular.faMoon} />
              </IconButton>
              <Typography className={classes.navLink} component={Link} to={window.location.hostname.includes("blog") ? process.env.REACT_APP_PUBLIC_URL : process.env.REACT_APP_BLOG_URL} variant="h6" align="center">
                {window.location.hostname.includes("blog") ? (
                  <FormattedMessage id="home" defaultMessage="Home" />
                ) : (
                  <FormattedMessage id="blog" defaultMessage="Blog" />
                )}
              </Typography>
              <Typography className={classes.navLink} component={Link} to={process.env.REACT_APP_PUBLIC_URL + "/about"} variant="h6" align="center">
                <FormattedMessage id="about" defaultMessage="About" />
              </Typography>
              <Typography className={classes.navLink}>
                <a href='https://ko-fi.com/B0B41QX3H' target='_blank'>
                  <img height='36' style={{border: '0px', height:'36px'}} src='https://storage.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
                </a> 
              </Typography> 
              <LanguageSelector onLanguageChange={handleLanguageChange} />
              <Toolbar className={classes.toolbar}>
                {user && !window.location.hostname.includes("blog") ? (
                  <div className={classes.profile}>
                    <Button style={{ textTransform: 'none', borderRadius: "1.5rem" }} className="p-0" onClick={() => setMenuOpen(true)}>
                      <AvatarIcon username={user?.result.username} avatar={user?.result.avatar} />
                      <Typography className={[classes.textColor, "px-3"]} variant="h6">
                        {user?.result.name.split(" ")[0]}
                      </Typography>
                    </Button>
                  </div>
                ) : (
                  <Button component={Link} to={window.location.hostname.includes("blog") ? process.env.REACT_APP_PUBLIC_URL + "/login" : "login"} variant="contained" color="primary">
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </Button>
                )}
              </Toolbar>
              </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

// export default Navbar;
