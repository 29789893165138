//ResetPassword.js 

import { React, useState } from "react";
import {
    useSearchParams,
    useNavigate
} from "react-router-dom";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    Card,
    CardContent
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetPassword } from "actions/auth";

import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";
import { FormattedMessage } from "react-intl";

const ResetPassword = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

      const theme = useTheme();
  const classes = useStyles(theme);

    const [message, setMessage] = useState  ("");

    const userId = searchParams.get("id");
    const token = searchParams.get("token");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const newpassword = data.get("newpassword");
        const confirmpassword = data.get("confirmpassword");
        if (newpassword !== confirmpassword){
            toast.error(`New Password and 
                         Confirm Password do not match !`, {
                autoClose: 5000,
                position: "top-right",
            });
            setMessage("Passwords do not match.");
        }
        else {

            dispatch(resetPassword({password: newpassword, confirmPassword: confirmpassword, token, userId})).then(data => {
                console.log(data)
                try{
                    if (data.success) {
                        toast.error(data.message, {
                            autoClose: 5000,
                            position: "top-right",
                        });
                        setMessage("Password reset success. You will be redirected to login page.");
                        setTimeout(() => {
                            navigate("/login");
                        }, 2000);
                    } else {
                        toast.success(data.message, {
                            autoClose: 5000,
                            position: "top-right",
                        });
                        setMessage("Password reset failed. Please try again.");
                    }
                }
                catch(e){
                    console.log(e)
                    toast.success("An error occured. Please, try later", {
                        autoClose: 5000,
                        position: "top-right",
                    });
                    setMessage("An error occured. Please, try later");
                }
            });
        }
    };

    return (
        <Container maxWidth="sm" className={classes.text}>
            <Box
                sx={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Card sx={{ boxShadow: "4" }}>
                    <CardContent sx={{ m: 3 }}>
                        <Avatar sx={{ m: "auto", 
                                      bgcolor: "primary.main" }}>
                            <LockResetIcon />
                        </Avatar>
                        <Typography component="h1" 
                                    variant="h5" 
                                    sx={{ mt: 1 }}>
                            <FormattedMessage id="Reset Password" defaultMessage="Reset Password" />
                        </Typography>

                        <Box component="form"
                             onSubmit={handleSubmit} 
                             sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="newpassword"
                                id="newpassword"
                                label={<FormattedMessage id="New Password" defaultMessage="New Password" />}
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="confirmpassword"
                                id="confirmpassword"
                                label={<FormattedMessage id="Confirm Password" defaultMessage="Confirm Password" />}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {<FormattedMessage id="Submit" defaultMessage="Submit" />}
                            </Button>
                        </Box>
                    </CardContent>
                    {message && (
                        <Typography variant="body2" 
                                    color="textSecondary" 
                                    align="center">
                            {<FormattedMessage id={message} defaultMessage={message} />}
                        </Typography>
                    )}
                </Card>
            </Box>
        </Container>
    );
};

export default ResetPassword;
