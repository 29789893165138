import React, { useState } from 'react';
import { Container, Typography, Checkbox, FormControlLabel, Button, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";

// Styles for the component
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  list: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  text: (theme) => ({
    color: theme.textColorSoft
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
}));

// Acknowledgment Component
const Acknowledgment = ({ onAgree }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleAgreeClick = () => {
    if (checked) {
      onAgree(); // Call the function passed as a prop to handle agreement
    }
  };

  return (
    <Container maxWidth="md" className="my-4">
        <div className={`${classes.bgSoft} rounded p-3 ${classes.text}`}>

        <Typography variant="h4" className={classes.title}>
          Acknowledgment of Use
        </Typography>
        <Typography variant="body1" className={classes.content}>
          By registering an account and using the Hackmedown platform, you acknowledge and agree to the following terms:
        </Typography>
        
        <Typography variant="h6" className={classes.sectionTitle}>
          1. Educational Purposes Only
        </Typography>
        <Typography variant="body2" className={classes.content}>
          Hackmedown is an educational platform designed to teach users about web vulnerabilities and cybersecurity through interactive challenges. All the information, techniques, and methodologies shared on this website are intended for educational and lawful purposes only. The platform aims to increase awareness and understanding of cybersecurity concepts to promote ethical behavior in the digital space.
        </Typography>
        
        <Typography variant="h6" className={classes.sectionTitle}>
          2. Prohibited Activities
        </Typography>
        <Typography variant="body2" className={classes.content}>
          You agree that any knowledge or skills acquired through Hackmedown must not be used for unauthorized or unlawful purposes, including, but not limited to, the following:
        </Typography>
        <ul className={classes.list}>
          <li>Attempting to gain unauthorized access to networks, servers, or applications.</li>
          <li>Launching attacks or exploiting vulnerabilities on systems that you do not have explicit permission to test.</li>
          <li>Engaging in activities that violate any applicable law, regulation, or ethical guidelines.</li>
        </ul>
        <Typography variant="body2" className={classes.content}>
          Any violation of these principles will result in immediate suspension or termination of your account and may be reported to relevant authorities.
        </Typography>
        
        <Typography variant="h6" className={classes.sectionTitle}>
          3. No Liability for Misuse
        </Typography>
        <Typography variant="body2" className={classes.content}>
          Hackmedown and its creators will not be held liable for any misuse of the information provided on the platform. You are solely responsible for ensuring that your actions, both during and after your use of Hackmedown, comply with applicable laws and ethical standards. Hackmedown disclaims any responsibility for any legal damages or consequences resulting from the misuse or abuse of the knowledge obtained through this platform.
        </Typography>
        
        <Typography variant="h6" className={classes.sectionTitle}>
          4. Personal Responsibility
        </Typography>
        <Typography variant="body2" className={classes.content}>
          By using Hackmedown, you acknowledge that it is your personal responsibility to use the knowledge and skills gained in an ethical and lawful manner. You agree not to engage in any illegal activities, hacking attempts, or unauthorized testing of systems without explicit written permission from the system owners.
        </Typography>
        
        <Typography variant="h6" className={classes.sectionTitle}>
          5. Disclaimer
        </Typography>
        <Typography variant="body2" className={classes.content}>
          Hackmedown provides the content and challenges on an "as is" basis without any warranty of any kind, express or implied. Hackmedown does not endorse, promote, or encourage hacking activities outside the scope of ethical and authorized testing. The platform shall not be liable for any indirect, incidental, special, or consequential damages arising from your participation in or use of the platform.
        </Typography>

        <Typography variant="body1" className={classes.content}>
          By registering and using the Hackmedown platform, you agree to the above acknowledgment and affirm that you understand the implications and responsibilities associated with the knowledge and skills you gain here. If you do not agree to these terms, please refrain from registering or using this platform.
        </Typography>
        </div>
        
    </Container>
  );
};

export default Acknowledgment;
