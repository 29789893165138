import { GET_TOP_USERS } from "../constants/actionTypes.js";

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export default (challenges = initialState, action) => {
    switch (action.type) {
        case GET_TOP_USERS:
            return { data: [...action.payload ], loading: false, error: null };
        default:
            return challenges;
    }
};
