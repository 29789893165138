import React, { useState, useEffect, useContext } from "react";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { DarkMode } from "./contexts/DarkMode.js";
import { ThemeProvider } from 'styled-components';
import { dark, light } from "./themes.js";
import "bootstrap/dist/css/bootstrap.min.css";
import useStyles from "./styles.js";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import './App.css';
import Home from "./Components/Home/Home.js";
import Auth from "./Components/Auth/Auth.js"; 
import { Navbar } from "./Components/Navbar/Navbar.js";
import { SidebarMenu } from "./Components/SidebarMenu/SidebarMenu.js";
import Instructions from "./Components/Instructions/Instructions.js";
import Challenge from "./Components/Challenge/Challenge.js";
import Description from "./Components/Challenge/Description/Description.js";
import Footer from "./Components/Footer/Footer.js";
import { ModalContextProvider } from "./contexts/ModalContext.js";
import { SideMenuContextProvider } from "./contexts/SideMenuContext.js";
import About from "./Components/About/About.js";
import { SolutionsContextProvider } from "./contexts/SolutionsContext.js";
import { ChallengeDataProvider } from "contexts/ChallengeData.js";
import Blog from "Components/Blog/Blog.js";
import BlogPosts from "Components/Blog/Posts/BlogPosts.js";
import BlogPost from "Components/Blog/Posts/Post/BlogPost.js";
import ExperienceQuiz from "Components/ExperienceQuiz/ExperienceQuiz.js";
import CompletedSurvey from "Components/CompletedSurvey/CompletedSurvey.js";
import NotFoundPage from "Components/Error/NotFoundPage.js";
import ThankYouPage from "Components/CompletedSurvey/ThankYou.js";
import { IntlProvider } from 'react-intl';
import translations from './translations.json';
import LanguageSelector from "Components/LanguageSelector/LanguageSelector.js";
import QuestionnairePage from "Components/Questionnaire/Questionnaire.js";
import { EmailVerificationSent } from 'Components/Pages/EmailVerificationSent.js';
import AllChallenges from 'Components/Challenge/AllChallenges/AllChallenges.js';
import Leaderboard from 'Components/Leaderboards/Leaderboards.js';
import { VerifyEmail } from 'Components/Pages/VerifyEmail.js';
import { ResendEmailVerification } from "Components/Pages/ResendEmailVerification.js";
import ForgotPassword from "Components/Pages/ForgotPassword.js";
import ResetPassword from "Components/Pages/ResetPassword.js";
import ChallengeLevel from "Components/Challenge/ChallengeLevel/ChallengeLevel.js";
import PrivacyPolicy from "Components/Privacy/Privacy.js";
import TermsOfService from "Components/Terms/TermsOfService.js";
import Acknowledgment from "Components/Acknowledgment/Acknowledgment.js";
import Cookies from "Components/Cookies/Cookies.js";
import { createTheme } from '@mui/material/styles';
import { LangContext, LangContextProvider } from "contexts/LangProvider.js";

// Define the theme conversion function to use with MUI
const getMuiTheme = (theme, isDarkMode) => createTheme({
  palette: {
    mode: isDarkMode === true ? 'dark' : 'light', // Determine light/dark mode based on `bgSoft`
    primary: {
      main: theme.logo, // Use the `logo` color for primary
    },
    background: {
      default: theme.bg, // Set the default background color
      paper: theme.bgSoft, // Set paper component background color
    },
    text: {
      primary: theme.textColor,
      secondary: theme.textColorSoft,
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Set a default font family
  },
  textColor: theme.textColor,
  darkModeSwitch: theme.darkModeSwitch,
  bg: theme.bg,
  logo: theme.logo,
  bgSoft: theme.bgSoft,
  bgHover: theme.bgHover,
  textColorSoft: theme.textColorSoft,
  border: theme.border,
  overlay:  theme.overlay,
});

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const theme = isDarkMode ? dark : light;
  const muiTheme = getMuiTheme(theme, isDarkMode);

  console.log(muiTheme, "muiTheme");

  const user = useSelector((state) => state.auth);
  const classes = useStyles(theme);
  const [locale, setLocale] = useState(''); // state for current locale

  // Set up dark mode detection based on system preferences
  useEffect(() => {

    // get dark mode or light mode from local storage
    const darkMode = localStorage.getItem('darkMode');
    console.log(darkMode, "darkMode");
    if(darkMode === 'true'){
      setIsDarkMode(true);
      // store to local storage
      localStorage.setItem('darkMode', true);
    }
    else{
      setIsDarkMode(false);
      // store to local storage
      localStorage.setItem('darkMode', false);
    }

    if(darkMode == undefined)
    {
      // Check if window and window.matchMedia are available (important for server-side rendering)
      if (typeof window !== 'undefined' && window.matchMedia) {
        // Detect initial system preference
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(darkModeMediaQuery.matches);
        // store to local storage
        localStorage.setItem('darkMode', darkModeMediaQuery.matchess);
  
        // Add event listener for system theme changes
        const handleThemeChange = (event) => {
          setIsDarkMode(event.matches);
          // store to local storage
          localStorage.setItem('darkMode', event.matches);
        };
  
        darkModeMediaQuery.addEventListener('change', handleThemeChange);
  
        // Cleanup event listener on component unmount
        return () => {
          darkModeMediaQuery.removeEventListener('change', handleThemeChange);
        };
      }
    }
  }, []);

  useEffect(() => {
    const localeVal = localStorage.getItem('locale');
    if (localeVal) {
      if(locale !== localeVal && locale !== ''){
        // store langauge in local storage
        localStorage.setItem("locale", locale);
        setLocale(locale);
      } else {
        setLocale(localeVal);
        localStorage.setItem("locale", localeVal);
      }
    } else {
      setLocale('en');
      localStorage.setItem("locale", 'en');
    }
  }, [locale]);



  return (
    <LangContextProvider locale={locale} setLocale={setLocale}>
      <ThemeProvider theme={muiTheme}>
        <Router>
          <AppContent user={user} theme={theme} classes={classes} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
        </Router>
      </ThemeProvider>
    </LangContextProvider>
  );
}



function AppContent({ user, theme, classes, isDarkMode, setIsDarkMode }) {
  const location = useLocation();
  const isFrameRoute = location.pathname.includes('/challenge/') && location.pathname.includes('/frame');

  const {locale, setLocale} = useContext(LangContext);

  const [messages, setMessages] = useState(translations[locale]); // state for current locale messages

  useEffect(() => {
    setMessages(translations[locale]);
  }, [locale]);


  // Check if the current path is the home page
  const isHomePage = location.pathname === '/';

  return (
    <div className={classes.bg}>
      <IntlProvider locale={locale} messages={messages}>
        <ModalContextProvider>
          <SideMenuContextProvider>
            <DarkMode.Provider value={{isDarkMode, setIsDarkMode}}>
              <ThemeProvider theme={theme}>
                <SolutionsContextProvider>
                  <Container maxidth="lg" className="d-flex flex-column p-0" style={{ minHeight: '100vh', width: "100%" }}>
                    {!isFrameRoute && <Navbar />}
                    <SidebarMenu />
                    <Routes>
                      {window.location.host.split('.')[0] === 'blog' ? (
                        <>
                          <Route path="/" element={<Blog />} />
                          <Route path="/:category" element={<BlogPosts />} />
                          <Route path="/:category/:slug" element={<BlogPost />} />
                        </>
                      ) : ( 
                        <>
                          <Route path="/" exact element={<Home />} />
                          <Route path="/leaderboard" exact element={<Leaderboard />} />
                          <Route path="/challenges" exact element={<AllChallenges />} />
                          <Route path="/about" exact element={<About />} />
                          <Route path="/email-verification-sent" element={<EmailVerificationSent />} />
                          <Route path="/resend-verification" element={<ResendEmailVerification />} />
                          <Route path="/verify-email" element={<VerifyEmail />} />
                          <Route path="/forgot-password" element={<ForgotPassword />} />
                          <Route path="/reset-password" element={<ResetPassword />} />
                          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                          <Route path="/cookies-policy" element={<Cookies />} />
                          <Route path="/terms-of-service" element={<TermsOfService />} />
                          <Route path="/acknowledgment" element={<Acknowledgment />} />
                          <Route path="/login" exact element={
                            user ? <Navigate to="/instructions" replace={true} /> : <Auth screen="login" />
                          } />
                          <Route path="/intro-survey" exact element={
                            user ? <ExperienceQuiz start="true" /> : <Auth screen="login" />
                          } />
                          <Route path="/completed" exact element={
                            user ? <CompletedSurvey start="true" /> : <Auth screen="login" />
                          } />
                          <Route path="/thank-you" exact element={
                            user ? <ThankYouPage start="true" /> : <Auth screen="login" />
                          } />
                          <Route path="/register" exact element={
                            user ? <Navigate to="/" replace={true} /> : <Auth screen="register" />
                          } />
                          <Route path="/instructions" exact element={
                            !user ? <Auth screen="register" /> : <Instructions />
                          } />
                          <Route path="/not-found" exact element={<NotFoundPage />} />
                          <Route path="/challenge/:challenge/pre-questionnaire" exact element={
                            !user ? <Auth screen="register" /> : <QuestionnairePage pre={true} />
                          } />
                          <Route path="/challenge/:challenge/post-questionnaire" exact element={
                            !user ? <Auth screen="register" /> : <QuestionnairePage pre={false} />
                          } />
                          <Route path="/challenge/:challenge/description" exact element={
                            !user ? <Auth screen="register" /> : <Description />
                          } />
                          <Route path="/challenge/:challenge" exact element={
                            !user ? <Auth screen="register" /> : <Challenge />
                          } />
                          <Route path="/challenge/:challenge/frame" exact element={
                            !user ? <Auth screen="register" /> : <ChallengeDataProvider><ChallengeLevel /></ChallengeDataProvider>
                          } />
                          {/* Default Route for 404 Page */}
                          <Route path="*" element={<NotFoundPage />} />
                        </>
                      )}
                    </Routes>
                    {(!isFrameRoute && !isHomePage) && <Footer />}
                  </Container>
                </SolutionsContextProvider>
              </ThemeProvider>
            </DarkMode.Provider>
          </SideMenuContextProvider>
        </ModalContextProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
