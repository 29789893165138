import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
  content: {
    padding: "1rem",
  },
  bg: (theme) => ({
    backgroundColor: theme.bg,
    // minHeight: "100vh",
  }),
}));
