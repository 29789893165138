import { FETCH_SOCIAL_POSTS, FETCH_SOCIAL_POSTS_ERROR, FETCH_SOCIAL_USERS_ERROR, FETCH_SOCIAL_USERS, FETCH_SOCIAL_USER_ERROR, FETCH_SOCIAL_USER, CREATE_SOCIAL_POST, CREATE_SOCIAL_POST_ERROR} from "../constants/actionTypes.js";

const initialState = {
  data: {users:[], posts:[]},
  loading: false,
  error: null,
}

export default (bestSocial = initialState, action) => {
  switch (action.type) {
    case FETCH_SOCIAL_POSTS:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, posts: action?.payload}, loading: false, error: null};
    case FETCH_SOCIAL_USERS:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, users: action?.payload}, loading: false, error: null};
    case FETCH_SOCIAL_USER:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, profile: action?.payload}, loading: false, error: null};
    case CREATE_SOCIAL_POST:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, posts: [action?.payload, ...bestSocial.data.posts]}, loading: false, error: null};
    case FETCH_SOCIAL_USER_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, profile: null}, loading: false, error: null};
    case FETCH_SOCIAL_POSTS_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, posts:[]}, loading: false, error: action?.payload};
    case FETCH_SOCIAL_USERS_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, users:[]}, loading: false, error: action?.payload};
    case CREATE_SOCIAL_POST_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...bestSocial.data, posts:[...bestSocial.data.posts]}, loading: false, error: action?.payload};
    default:
        return bestSocial;
  }
};
