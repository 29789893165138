import { IconButton, Tab, Tabs } from '@material-ui/core';
import { changeBrowser, changeTab } from 'actions/challenge';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs,
    getBrowsers
  } from '../../../selectors/browser.js';


import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import CustomTabs from 'Components/CustomTabs/CustomTabs.js';

const BrowserSwitch = () => {

        const page = useSelector(getPage);
        const browsersAndTabs = useSelector(getBrowsersAndTabs);
        const browsers = useSelector(getBrowsers);
        const user = useSelector(getUser);
        const params = useSelector(getParams);
        const uid = useSelector(getUid);
        const url = useSelector(getUrl);
        const tabs = useSelector(getTabs);
        const domain = useSelector(getDomain);
        const undoHistory = useSelector(getUndoHistory);
        const undoHistoryPointer = useSelector(getUndoHistoryPointer);
        const session = useSelector(getSession);
        const activeTab = useSelector(getActiveTab);
        const selectedBrowser = useSelector(getSelectedBrowser);

        const theme = useTheme();
        const classes = useStyles(theme);

    // console.log(browsers, selectedBrowser, "browsers, selectedBrowser")

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeBrowser = (browser) => {

        if (browser === selectedBrowser) return;

        // console.log(browsersAndTabs, browser, "browsersAndTabs browser")

        // check if the activeTab is in the tabs of the selected browser if not set the activeTab to the first tab

        console.log(browser, "browser change")

        dispatch({type: "UPDATE_SELECTED_BROWSER", payload: browser});
        dispatch({type: "UPDATE_TABS", payload: browsersAndTabs[browser]});

        // console.log(browser)
        
        // const expirationDate = new Date();
        // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
        // document.cookie = `browser=${browser}; expires=${expirationDate.toUTCString()}; path=/;`;

        // console.log(session, selectedBrowser, document.cookie)

        dispatch(changeBrowser({browser: browser})).then(() => {
            // localStorage.getItem('session_id')
        });

        // console.log(browsersAndTabs[browser], browser, activeTab, "browsersAndTabs[browser], activeTab")
        if (!browsersAndTabs[browser].includes(activeTab)) {
            dispatch(changeTab({tab: activeTab})).then(() => {
                dispatch({type: "UPDATE_ACTIVE_TAB", payload: browsersAndTabs[browser][0]});
                console.log("Tab changed step added");
            });
        }
        
        navigate(`?browser=${browser}`);

        // refresh the page
        // window.location.reload(false);
    }

    return (
        <div className={classes.text} style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
            <CustomTabs
                browsers={browsers}
                selectedBrowser={selectedBrowser}
                handleChangeBrowser={handleChangeBrowser}
            />
        </div>
    );
};

export default BrowserSwitch;
