// src/LoginPage.js
import React, { useContext, useDebugValue, useEffect, useState } from 'react';
import { Container, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {BankAuthContext, getTransfers, transfer} from "../../../../../actions/bankScenario.js";
 
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Menu from './Menu/Menu.js';
import Savings from './Savings/Savings.js';
import Transaction from './Transaction/Transaction.js';
import Transactions from './Transactions/Transactions.js';
import {BankLogOut} from '../../../Common/BankLogOut.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { bankScenarioAuth } from 'actions/challenge.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';

const UserProfile = () => { 

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

    useEffect(() => {

      console.log(activeTab, page, localStorage.getItem('page'), "dashboard in bank")
      if (!activeTab) return
      


    }, [activeTab, page]);


    const theme = useTheme();
    const classes = useStyles(theme);

    return (
<Container maxWidth="lg" className="h-100 position-relative d-flex flex-column justify-content-between">
  {/* Sticky Logout Button at the Top */}
  <div className="d-flex justify-content-end p-3">
    <BankLogOut />
  </div>

  {/* Dynamic Content That Fills the Remaining Space */}
  <div className="flex-grow-1 d-flex justify-content-center align-items-center">
    {page === "menu" && <Menu />}
    {page === "savings" && <Savings />}
    {page === "transaction" && <Transaction />}
    {page === "transactions" && <Transactions />}
  </div>
</Container>

    );
};

export default UserProfile;