import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, CircularProgress, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { useDispatch } from 'react-redux';
import { getHint } from 'actions/challenge.js';
import { Tab, Tabs } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const Hint = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content} = props;
 
  const theme = useTheme();
  const classes = useStyles(theme);

  const [hintContent, getHintContent] = useState([]);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  useEffect(() => {
    if(!isOpen) return;

    if(isOpen === "hint") {
      console.log('fetch solution...');
      // fetching solution for this step
      dispatch(getHint()).then((response) => {
        console.log('response', response);
        getHintContent(response.hint);
      });
    }

  }, [isOpen]);

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (newValue) => {
    console.log(newValue)
    setActiveTab(newValue);
  };

  return (
    <Modal
      open={isOpen === "hint"}
      onClose={handleCloseModal} 
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modalContainer}
    >
      <Box className={classes.box}>
        <h2 id="modal-title" className={classes.text}><FormattedMessage id="Hints" defaultMessage="Hints" /></h2>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleChangeTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          {hintContent.length > 0 && typeof hintContent !== "string" &&
            hintContent.map((hint, index) => (
              <Tab key={index} className={classes.text} label={
                <Typography className={classes.text}>
                  <FormattedMessage id="Hint {number}" defaultMessage="Hint {number}" values={{number: index + 1}} />
                </Typography>
            } />
            ))}
        </Tabs>
        <Box className={`${classes.textContent} text-start flex-column w-100 text-center`} style={{ display: 'flex' }}>
          {typeof hintContent === "string" && (
            <Typography>
              {hintContent}
            </Typography>
          )}

          { typeof hintContent !== "string" && <>
            {hintContent.length > 0 ? (
              <Typography>
                {hintContent[activeTab]}
              </Typography>
            ) : (
              <Box className='w-100 d-flex justify-content-center'>
                <CircularProgress />
              </Box>
            )}
          </>
          }
        </Box>
        <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
          <FormattedMessage id="Got it" defaultMessage="Got it" />
        </Button>
      </Box>
    </Modal>
  );
};

export default Hint;