import { useTheme } from "styled-components";
import {
    TextField,
    makeStyles,
  } from "@material-ui/core"; 

const inputStyles = makeStyles((theme) => ({
    root: (theme) => ({
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.textColorSoft, // Default border color
        },
        '&:hover fieldset': {
          borderColor: theme.textColorSoft, // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.textColorSoft, // Border color when focused
        },
        overflow: "hidden",
      },
      '& fieldset': { 
        top: "0px", // Text color inside input
      },
      '& .MuiInputLabel-root.Mui-focused': { 
        backgroundColor: theme.bgSoft, // Text color inside input
      },
      '& input': {
        color: theme.textColor, // Text color inside input
      },
      '& input::placeholder': {
        color: theme.textColorSoft, // Placeholder color
        opacity: 1,
      },
      '& label.Mui-focused': {
        color: theme.textColorSoft, // Color of the label when focused
      },
      '& label': {
        color: theme.textColorSoft, // Color of the label when focused
      },
      // '& input:-internal-autofill-selected': {
      //   backgroundColor: theme.bgSoft+"!important", // Color of the label when focused
      // },
      '& .MuiInputBase-input': {
        backgroundColor: theme.bgSoft+"!important", // Color of the label when focused
      },
    }),
  }));
  
  const AuthInput = ({ label, handleChange, ...props }) => {
    const theme = useTheme();
    const classes = inputStyles(theme);
  
    return (
      <TextField
        variant="outlined" // Use outlined variant
        fullWidth
        label={label}
        onChange={handleChange}
        className={`${classes.root} m-0`} // Apply custom styles
        {...props}
      />
    );
  };

  export default AuthInput;