const initialState = {
    challengeData: [],
    page: {},
    browsersAndTabs: {},
    user: {},
    params: {},
    tabs: [],
    activeTab: null,
    uid: {},
    selectedBrowser: null,
    browsers: [],
    url: {},
    allUrls: [],
    domain: {},
    restart: false,
    session: { status: false },
    undoHistory: {},
    undoHistoryPointer: {},
    scriptValid: false,
  };
  
  export default (state = initialState, action) => {
    const { activeTab, selectedBrowser } = state;
    const updateActiveTabState = (key, payload) => {
      // console.log(key, payload, "key, payload")
      if (activeTab) {
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, [key]: { ...state[key], [activeTab]: payload } };
      }
      return state;
    };
  
    switch (action.type) {
      case 'UPDATE_PAGE':
        return updateActiveTabState('page', action.payload);
      case 'UPDATE_BROWSER_AND_TABS':
        // console.log(action.payload, "browser and tabs")
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
      return { ...state, browsersAndTabs: action.payload };
      case 'UPDATE_USER':
        return updateActiveTabState('user', action.payload);
      case 'UPDATE_PARAMS':
        return updateActiveTabState('params', action.payload);
      case 'UPDATE_UID':
        return updateActiveTabState('uid', action.payload);
      case 'UPDATE_URL':
        console.log(action.payload, "url upifaye", selectedBrowser, activeTab, state.url)
        if (selectedBrowser && activeTab) {
          if (window.parent !== window) {
            window.parent.postMessage({ state: action }, "*");
          }
        
          return {
            ...state,
            url: {
              ...state.url,
              [selectedBrowser]: {
                ...state.url[selectedBrowser], // Spread the existing state for selectedBrowser
                [activeTab]: action.payload,   // Add or update the activeTab with the new payload
              },
            },
          };
        }
        return state;
      case 'UPDATE_DOMAIN':
        return updateActiveTabState('domain', action.payload);
      case 'UPDATE_UNDO_HISTORY':
        return updateActiveTabState('undoHistory', action.payload);
      case 'UPDATE_UNDO_HISTORY_POINTER':
        return updateActiveTabState('undoHistoryPointer', action.payload);
      case 'UPDATE_SESSION':
        if (selectedBrowser && activeTab) {
          return {
            ...state,
            session: {
              ...state.session,
              [selectedBrowser]: {
                ...state.session[selectedBrowser], // Spread the existing state for selectedBrowser
                [activeTab]: action.payload,   // Add or update the activeTab with the new payload
              },
            },
          };
        }
        return state;
      case 'UPDATE_SCRIPT_VALID':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, scriptValid: action.payload };
      case 'UPDATE_CHALLENGE_DATA':
        // if(window.parent !== window){
        //     window.parent.postMessage({state: action}, "*");
        // }  
        return { ...state, challengeData: action.payload };
      case 'UPDATE_TABS':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, tabs: action.payload };
      case 'UPDATE_ALL_URLS':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, allUrls: action.payload };
      case 'UPDATE_BROWSERS':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, browsers: action.payload };
      case 'UPDATE_SELECTED_BROWSER':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, selectedBrowser: action.payload };
      case 'UPDATE_ACTIVE_TAB':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, activeTab: action.payload };
      case 'UPDATE_RESTART':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return { ...state, restart: action.payload };
      case 'RESET_STATES':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  
        return initialState;
      case 'RESET_LOGIN':
        if(window.parent !== window){
            window.parent.postMessage({state: action}, "*");
        }  

        const updatedState = state;

        console.log()
        delete updatedState.session[action.selectedBrowser][action.activeTab];
        return updatedState;

      default:
        return state;
    }
  };
  
  // challengeData: [],
  // page: {},
  // browsersAndTabs: {},
  // user: {},
  // params: {},
  // tabs: [],
  // activeTab: null,
  // uid: {},
  // selectedBrowser: null,
  // browsers: [],
  // url: {},
  // allUrls: [],
  // domain: {},
  // restart: false,
  // session: { status: false },
  // undoHistory: {},
  // undoHistoryPointer: {},
  // scriptValid: false,