import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  navbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  appBar: (theme) => ({
    backgroundColor: theme.bgSoft, // Use theme.palette for background
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: theme.palette.mode === 'dark'
      ? "0px 0px 10px 0px rgba(255, 255, 255, 0.1)" // Light shadow in dark mode
      : "0px 0px 10px 0px rgba(0,0,0,0.45)", // Darker shadow in light mode
    zIndex: "1300",
  }),
  heading: (theme) => ({
    color: theme.logo, // Use text color from theme
    padding: "0rem 1rem",
    textDecoration: "none",
    lineHeight: 1.5,
    letterSpacing: "normal",
    wordBreak: "keep-all", // Prevent characters from breaking into new lines
    whiteSpace: "nowrap", // Ensure text does not wrap
    display: "inline-block", // Keep the text in a horizontal line
  }),
  mobileHeading: (theme) => ({
    color: theme.logo, // Dynamic color based on theme
    textDecoration: "none",
    fontSize: "1.5rem",
    flexGrow: 1,
    lineHeight: 1.5,
    letterSpacing: "normal",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    display: "inline-block",
  }),
  textColor: (theme) => ({
    color: theme.palette.text.primary, // Dynamic text color based on theme
    lineHeight: 1.5,
    letterSpacing: "normal",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    display: "inline-block",
  }),
  navLink: (theme) => ({
    color: theme.palette.text.secondary, // Use secondary text color for nav links
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main, // Hover effect changes to primary color
    },
    padding: "0rem 1rem",
    lineHeight: 1.5,
    letterSpacing: "normal",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    display: "inline-block",
  }),
  darkModeSwitch: (theme) => ({
    color: theme.darkModeSwitch, // Dynamic switch color based on theme
  }),
  image: {
    marginLeft: "15px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "end",
    padding: "0px",
    width: "100%",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userName: (theme) => ({
    display: "flex",
    alignItems: "center",
    lineHeight: 1.5,
    letterSpacing: "normal",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    display: "inline-block",
    color: theme.palette.text.primary, // Dynamic text color for username based on theme
  }),
  brandContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  drawer: (theme) => ({
    width: 250,
    backgroundColor: theme.palette.background.default, // Drawer background based on theme
    color: theme.palette.text.primary, // Drawer text color based on theme
    height: "100%",
  }),
}));
