import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { Button, CircularProgress } from '@material-ui/core';
import { useTheme } from "styled-components";

import useStyles from "../styles.js";
import { useDispatch, useSelector } from 'react-redux';
import { BankAuthContext, getBalance } from '../../../../../../actions/bankScenario.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Savings = (props) => {
  // Replace this with actual data
  const interestRate = 0.03; // Annual interest rate

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

  const [accountInfo, setAccountInfo] = useState({});
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const {challenge} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {

    if (!session.user) return;

    if(session.user)
    {
      let sessionId = null;

      if(challenge == "challenge7"){
        // get session_id from the cookie
        if(document.cookie.includes("session_id")){
          sessionId = document.cookie.split("; ").find(row => row.startsWith("session_id")).split("=")[1];
        }
      }

      dispatch(getBalance({username: session.user.username, browser: selectedBrowser, sessionId: sessionId })).then(data => {
        console.log(data)
        if(data){
          if(data.user){

            data.user.balance = Number(data.user.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            setAccountInfo(data.user);
            setLoading(false);
            
          }
          else if(data.message){
            setMessage(data.message);
          }
        }
      })
    }
  }, [session])

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container maxWidth="md"  className="d-flex justify-content-center h-100 flex-column align-items-center">
        <div  className={classes.text}>

        <Typography variant="h4" align="center" style={{ margin: '20px' }}>
            <FormattedMessage id="Savings Account" defaultMessage="Savings Account" ></FormattedMessage>
        </Typography>

        {message ? <Typography variant="h6" style={{ color: 'red' }}>{message}</Typography>
        : <>
        {loading ? 
          <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </Container>
        : 
            <>
                <Typography variant="h6"><FormattedMessage id="username" defaultMessage="Username" ></FormattedMessage>: {accountInfo.username}</Typography>
                <Typography variant="h6"><FormattedMessage id="Account Number" defaultMessage="Account Number" ></FormattedMessage>: {accountInfo.accountNumber && accountInfo.accountNumber}</Typography>
                <Typography variant="h6"><FormattedMessage id="Sort Code" defaultMessage="Sort Code" ></FormattedMessage>: {accountInfo.accountNumber && accountInfo.sortCode}</Typography>
                <Typography variant="subtitle1"><FormattedMessage id="Last Transaction" defaultMessage="Last Transaction" ></FormattedMessage>: {new Date(accountInfo.lastTransaction).toLocaleString()}</Typography>
                <Typography variant="h5" style={{ marginTop: '20px' }}>
                    <FormattedMessage id="Total Savings" defaultMessage="Total Savings" ></FormattedMessage>: {accountInfo.balance}<FormattedMessage id="£" defaultMessage="£" ></FormattedMessage>
                </Typography>
                <Typography variant="subtitle1">
                    <FormattedMessage id="Interest Rate" defaultMessage="Interest Rate" ></FormattedMessage>: {interestRate * 100}% (<FormattedMessage id="Annual" defaultMessage="Annual" ></FormattedMessage>)
                </Typography>
            </>
            }
        </>
}
        </div>

        {!loading ? 
        <div className="w-100 d-flex justify-content-end">
          <Button variant="contained" className="my-3" color="primary" onClick={() => dispatch({type: "UPDATE_URL", payload:"https://www.bank.com"})}>
          <FormattedMessage id="back" defaultMessage="Back" ></FormattedMessage>
            </Button>
        </div>
        : null
        }
      {/* Additional relevant information can be displayed here */}
    </Container>
  );
};

export default Savings;