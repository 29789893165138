import { createContext, useEffect, useState  } from "react";

export const LangContext = createContext();

export const LangContextProvider = ({children}) => {

    // check if the locale is stored in local storage
    
    const [locale, setLocale] = useState(() => {
        // Initialize locale from localStorage or default to 'en'
        return localStorage.getItem('locale') || 'en';
      });
    
      useEffect(() => {
        localStorage.setItem('locale', locale);
      }, [locale]);

    return (
        <LangContext.Provider value={{locale, setLocale}}>
            {children}
        </LangContext.Provider>
    );
};