import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: (theme) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  video: (theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure video covers the full viewport
    zIndex: 0,
  }),
  overlay: (theme) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: theme.overlay || 'rgba(0, 0, 0, 0.5)', // Dark overlay based on theme
  }),
  demoSection: (theme) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    width: "100%",
    color: theme.textColor, // Use theme's text color
  }),
  demoVideo: (theme) => ({
    maxWidth: '800px',
    width: '100%',
    paddingTop: "1rem",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  }),
  heroSection: (theme) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 2,
    animation: '$fadeIn 1.5s ease-in-out',
  }),
  heroContent: (theme) => ({
    position: 'relative',
    zIndex: 2,
    color: theme.textColor || '#fff', // Use theme's text color
    padding: theme.spacing(4),
  }),
  heroTitle: (theme) => ({
    marginBottom: theme.spacing(2),
    fontWeight: '500',
    color: theme.textColor || '#fff', // Use theme's text color
    fontSize: '3rem',
    animation: '$slideUp 1s ease-in-out',
  }),
  heroSubtitle: (theme) => ({
    marginBottom: theme.spacing(4),
    color: theme.textColor || '#fff', // Use theme's text color
    fontSize: '1.5rem',
    animation: '$slideUp 1.2s ease-in-out',
  }),
  featuresSection: (theme) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.bg || '#f5f5f5', // Use theme's background color
    textAlign: 'center',
    animation: '$fadeIn 2s ease-in-out',
  }),
  sectionTitle: (theme) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    fontSize: '2rem',
    color: theme.textColor || '#000', // Use theme's text color
  }),
  featureTitle: (theme) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main, // Primary color based on theme
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
  }),
  featureDescription: (theme) => ({
    color: theme.textColor || '#333', // Use theme's text color
  }),
  challengesSection: (theme) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.bgSoft || '#f0f0f0', // Use theme's soft background color
    textAlign: 'center',
    animation: '$fadeIn 2s ease-in-out',
  }),
  challengeTitle: (theme) => ({
    fontWeight: 'bold',
    color: theme.palette.secondary.main, // Secondary color based on theme
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
  }),
  challengeDescription: (theme) => ({
    color: theme.textColor || '#333', // Use theme's text color
  }),
  blogSection: (theme) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.bg || '#f0f0f0', // Use theme's background color
    textAlign: 'center',
    animation: '$fadeIn 2.5s ease-in-out',
  }),
  blogDescription: (theme) => ({
    marginBottom: theme.spacing(4),
    color: theme.textColor || '#333', // Use theme's text color
  }),
  contactSection: (theme) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.bgSoft || '#f0f0f0', // Use theme's soft background color
    textAlign: 'center',
    animation: '$fadeIn 3s ease-in-out',
  }),
  contactDescription: (theme) => ({
    marginBottom: theme.spacing(2),
    color: theme.textColor || '#333', // Use theme's text color
  }),
  contactEmail: (theme) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main, // Primary color based on theme
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  footer: (theme) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default, // Background color based on theme
    textAlign: 'center',
  }),
  footerText: (theme) => ({
    color: theme.textColor || '#333', // Footer text color based on theme
  }),

  // Animations
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  '@keyframes slideUp': {
    from: { transform: 'translateY(50px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },

  // Mobile responsiveness
  '@media (max-width: 768px)': {
    heroTitle: (theme) => ({
      fontSize: '2.5rem',
    }),
    heroSubtitle: (theme) => ({
      fontSize: '1.2rem',
    }),
    sectionTitle: (theme) => ({
      fontSize: '1.5rem',
    }),
    featureTitle: (theme) => ({
      fontSize: '1.2rem',
    }),
    challengeTitle: (theme) => ({
      fontSize: '1.2rem',
    }),
  },
}));
