import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { FormattedMessage } from 'react-intl';

const NotFoundPage = () => {

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container className={classes.container}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h1" className={classes.text}>
          404
        </Typography>
        <Typography variant="h5" className={classes.text}>
          <FormattedMessage id="Oops! Page not found." defaultMessage="Oops! Page not found."></FormattedMessage>
        </Typography>
        <Typography variant="body1" className={classes.text}>
          <FormattedMessage id="The page you are looking for might be under construction or doesn't exist." defaultMessage="The page you are looking for might be under construction or doesn't exist."></FormattedMessage>
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          <FormattedMessage id="Go to home" defaultMessage="Go to home"></FormattedMessage>
        </Button>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;
