import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { useDispatch } from 'react-redux';
import { openArticle } from 'actions/challenge.js';
import { FormattedMessage } from 'react-intl';

const ScenarioGuidance = (props) => {

    const {guidance, stepNumber, isLastStep} = props;
  const {isOpen, setIsOpen} = useContext(ModalContext);
  const [guidanceStep, setGuidanceStep] = useState([]);
  const [includeArticle, setIncludeArticle] = useState(false);

  const dispatch = useDispatch();
  const [userClickedYes, setUserClickedYes] = useState(false);
  const {category, slug} = props;

 
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {

    if( !userClickedYes) return; 
    if( isOpen == null) return; 

    window.open(`${process.env.REACT_APP_BLOG_URL || "https://blog.george.tardis.ac"}/${category}/${slug}`, '_blank');
    handleCloseModal();

    console.log(userClickedYes, isOpen)

  }, [isOpen, category, slug, dispatch, userClickedYes]);

  const handleCloseModal = () => {
    // check if this is the last step
    console.log(isLastStep)
    if (isLastStep){
        setIsOpen("finish");
    }
    else{
        setIsOpen(null);
    }
  };

  useEffect(() => {
    console.log(isOpen)
    if (!guidance) return;
    if (isOpen !== "guidance") return;
        console.log(guidance, stepNumber)
        for (let key in guidance) {
          if (guidance[key].stepNumber == stepNumber) {
            setGuidanceStep(guidance[key].description);
            console.log(guidance[key].includeArticle)
            setIncludeArticle(guidance[key].includeArticle);
            break;
          }
        }
  }, [isOpen, stepNumber, guidance]);

  return (
    <div>
        <Modal
            open={isOpen === "guidance"}
            onClose={handleCloseModal} 
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}><FormattedMessage id="Guidance" defaultMessage="Guidance" /></h2>
                <Box className={`${classes.textContent} text-start flex-column`} style={{ display: 'flex' }}>
                    <div >
                        <span style={{fontSize: "16px"}}>{guidanceStep}</span>
                    </div>
                </Box>
                {includeArticle ? 
                    <Button variant="contained" className="mt-3" onClick={() => setUserClickedYes(true)}>
                        <FormattedMessage id="Open Article" defaultMessage="Open Article" />
                    </Button>
                    : null
                }
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    <FormattedMessage id="Got it" defaultMessage="Got it" />
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default ScenarioGuidance;