// src/pages/EmailVerificationSent.js
import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";
import { FormattedMessage } from 'react-intl';

export const EmailVerificationSent = () => {

    const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.text} style={{ textAlign: 'center', padding: '50px' }}>
      <h1><FormattedMessage id="Email Verification Sent" defaultMessage="Email Verification Sent" /></h1>
      <p><FormattedMessage id="A verification email has been sent to your email address. Please check your inbox and click on the verification link to activate your account." defaultMessage="A verification email has been sent to your email address. Please check your inbox and click on the verification link to activate your account." /></p>
      <p><FormattedMessage id="If you did not receive the email, please check your spam folder or {link} to resend the verification email." defaultMessage="If you did not receive the email, please check your spam folder or {link} to resend the verification email." values={{link: <Link to="/resend-verification"><FormattedMessage id="click here" defaultMessage="click here" /></Link>}} /></p>
      <Link to="/login"><FormattedMessage id="Back to Login" defaultMessage="Back to Login" /></Link>
    </div>
  );
};
