import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
    root: (theme) => ({ 
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }),
      // card: {
      //   display: 'flex',
      //   marginBottom: theme.spacing(2),
      // },
      // content: {
      //   flex: '1 0 auto',
      // },
      txt: (theme) => ({
        color: theme.textColor,
      }),
      media: {
        objectFit: 'cover',
      },
      card: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.bgSoft,
        height: '100%', // Set card height to occupy the full container height
      }),
      content: (theme) => ({
        flex: '1 0 auto',
        padding: theme.spacing(2),
        textAlign: 'center',
        overflow: 'hidden', // Hide overflowed content
        textOverflow: 'ellipsis', // Show ellipsis for overflowed text
      }),
}));
