import React from "react";
import App from "./App.js";
import ReactDom from "react-dom/client";
// import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { AuthContextProvider } from "./actions/auth.js";
import "./style.scss";

import { HelmetProvider } from "react-helmet-async";
import { store } from "store.js";

if (process.env.NODE_ENV !== "development")
  console.log = () => {};

  const root = ReactDom.createRoot( document.getElementById("root"));
  root.render(
      <Provider store={store}>
        <HelmetProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </HelmetProvider>
      </Provider>
  );

// test

// npm start - starts the server
