import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const ChallengeCompletedModal = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const challengeInfo = useSelector(state => state.challenge);

  const {content, challenge} = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    navigate(`/challenge/${challengeInfo.data.seed}/post-questionnaire`);
    setIsOpen(null);
  };

  const openAllChallenges = () => {
    navigate(`/challenges`);
    setIsOpen(null);
  }

//   useEffect(() => {
//     console.log(isOpen)
//   },[isOpen])

  return (
    <div>
        <Modal
            open={isOpen === "finish"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}><FormattedMessage id="Challenge Complete" defaultMessage="Challenge Complete" /></h2>
                <Box className={classes.textContent}>
                    <Typography variant="p" className={classes.text}>
                      <FormattedMessage id="You have completed the challenge. Click on the {button} button below when you're ready to open the questionnaire." defaultMessage="You have completed the challenge. Click on the {button} button below when you're ready to open the questionnaire." values={{button: <Box fontWeight='fontWeightMedium' display='inline'><FormattedMessage id="Open Questionnaire" defaultMessage="Open Questionnaire" /></Box>}} />
                    </Typography>
                </Box> 
                <Button variant="contained" className="m-2" onClick={handleCloseModal}>
                    <FormattedMessage id="Open Questionnaire" defaultMessage="Open Questionnaire" />
                </Button>
                <Button variant="contained" className="m-2" onClick={openAllChallenges}>
                    <FormattedMessage id="Another Challenge" defaultMessage="Another Challenge" />
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default ChallengeCompletedModal;