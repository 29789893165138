import React, { useContext } from 'react';
import { Container, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChallengeDataContext } from 'contexts/ChallengeData';

import { useTheme } from "styled-components";
import useStyles from '../styles.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     textAlign: 'center',
//   },
//   button: {
//     marginTop: theme.spacing(3),
//   },
// }));

export const ThankYouPage = () => {

const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const theme = useTheme();
    const classes = useStyles(theme);

  const dispatch = useDispatch();

  const backHome = () => {
    dispatch({ type: 'UPDATE_PAGE', payload: 'menu' });
    dispatch({ type: 'UPDATE_URL', payload: `https://www.${activeTab}.com` });
  }

  return (
    <Container className={`${classes.bg} my-3`} maxWidth="md">
      <Paper className={classes.paper} elevation={3}>
        <div className={`${classes.bg} ${classes.text} d-flex justify-content-center flex-column`}>
          <Typography variant="h4" gutterBottom>
            <FormattedMessage id="Thank You for Your Purchase!" defaultMessage="Thank You for Your Purchase!" ></FormattedMessage>
          </Typography>
          <Typography variant="body1" paragraph>
            <FormattedMessage id="Your order has been successfully processed. We appreciate your business." defaultMessage="Your order has been successfully processed. We appreciate your business." ></FormattedMessage>
          </Typography>
          <Typography variant="body2" paragraph>
            <FormattedMessage id="Order Confirmation Number" defaultMessage="Order Confirmation Number" ></FormattedMessage>: {'ORD-' + Date.now().toString(36).toUpperCase() + '-' + Math.random().toString(36).substr(2, 5).toUpperCase()}
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => backHome()}
          >
            <FormattedMessage id="Back to Home" defaultMessage="Back to Home" ></FormattedMessage>
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default ThankYouPage;
