const clearCookies = () => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if(name.includes("_")){
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          // clear the same value from local storage
          localStorage.removeItem(name);
          console.log(name, "cookie removed")
        }
      }
}

export default clearCookies;