// src/App.js
import React, { useContext, useEffect } from 'react';
import { Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import { CircularProgress, Paper } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import Dashboard from './Dashboard/Dashboard.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from 'selectors/browser';
import { useSelector } from 'react-redux';

const ECommerceScenario = () => {

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    
    const theme = useTheme();
    const classes = useStyles(theme);
    
    // useEffect(() => {
    //     console.log('prior',url, session)
    //     if (session.status) {
    //         console.log('ηευυυυ',session)
    //     }
    // }, [url])

    // console.log(session, sessionTest, sessionTest.data[activeTab], activeTab)

    //' OR balance > 1000000

    // console.log(session, "my sessin changes")

    return (
        <Container component="main" className="m-0 p-0" maxWidth="md">
        {page == null ? 
        <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
            <CircularProgress />
        </Container> : 
        <>
            {page === "login" ? <LoginPage title="Login to your {platform} account." platform="Flopify" /> : <Dashboard />}
        </>}
    </Container>
    );
};

export default ECommerceScenario;