import { GET_ECOMMERCE_AUTH_USER, GET_PRODUCT, GET_PRODUCTS, GET_PRODUCTS_ERROR, GET_PRODUCT_ERROR } from "../constants/actionTypes.js";

const initialState = {
  data: {users:[], products:[]},
  loading: false,
  error: null,
}

export default (ecommerce = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...ecommerce.data, product: action?.payload}, loading: false, error: null};
    case GET_PRODUCTS:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...ecommerce.data, products: action?.payload}, loading: false, error: null};
    case GET_ECOMMERCE_AUTH_USER:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...ecommerce.data, authUser: action?.payload}, loading: false, error: null};
    case GET_PRODUCT_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...ecommerce.data, product: null}, loading: false, error: action?.payload};
    case GET_PRODUCTS_ERROR:
        if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
        return {data: {...ecommerce.data, products:[]}, loading: false, error: action?.payload};
    default:
        return ecommerce;
  }
};
