import { FormattedMessage } from "react-intl";
import useStyles from "./styles.js";
import { useTheme } from "styled-components";

const Copyright = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
 
    return(
      <>
        <div className={classes.text}>

          <div>
            <FormattedMessage
              id="copyright"
              defaultMessage="&copy; 2024 HackMeDown. All rights reserved."
            />
          </div>
          <FormattedMessage
            id="made-at"
            defaultMessage="Made with ❤️ at Edinburgh University"
          />
        </div>
      </>
    )
}

export default Copyright;