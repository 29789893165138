import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
  container: (theme) => ({
    textAlign: 'center',
    marginTop: "1rem",
  }),
  slider: (theme) => ({
    width: '80%',
    margin: 'auto',
    marginTop: "0.5rem",
  }),
  submitButton: (theme) => ({
    marginTop: "0.5rem",
  }),
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  heading: (theme) => ({
    color: theme.logo,
    textDecoration: "none",
  }),
  text: (theme) => ({
    color: theme.textColorSoft
  }),
}));