// ThankYouPage.js
import React, { useEffect, useState } from 'react';
import { Typography, Button, Container, CssBaseline, CircularProgress } from '@mui/material';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { surveysCompleted } from 'actions/survey.js';
import { getCompletedChallenges } from 'actions/challenge';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import useStyles from "./styles.js";
import { useTheme } from "styled-components";

const ThankYouPage = () => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [opened, setOpened] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [surveyToken, setSurveyToken] = useState(null);
    const [quiz, setQuiz] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // check if the user has finished all challenges. If not, throw an Error 404 not fond page
        if(!fetched)
        {
            dispatch(surveysCompleted()).then(data =>{
                setIsLoading(false)
                setSurveyToken(data.surveyToken)
                if(data.thankYouPageOpened){
                    setOpened(true);
                }
                if(data.quizes){
                    setQuiz(data.quizes);
                }
                if(data.challengesCompleted){
                    console.log('success');
                }
                else{
                    setError('An error happened, pleaase try again later.');
                    navigate("/not-found")
                }
                setFetched(true);
            }); // check if the user has completed all challenges
        }
        else{
            dispatch(getCompletedChallenges()).then(data =>{
                if(data.completedChallenges){
                    setCompletedChallenges(data.completedChallenges);
                    console.log(data)
                    console.log('success');
                }
                else{
                    setError('An error happened, pleaase try again later.');
                    navigate("/not-found")
                }
            })
        }

    }, [fetched])  

  return (
    <Container component="main"  className={`${classes.bg} ${classes.text}`}>
      <CssBaseline />
      {isLoading ? (
            <Grid container className="d-flex justify-content-center py-3">
                <Grid className='w-100 text-center'>
                        <CircularProgress />
                </Grid>
            </Grid>
        ): (
            <div
                style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '1rem',
                textAlign: 'center',
                height: '100%',
                }}
            >
                <Typography variant="h2" color="primary" gutterBottom>
                    <FormattedMessage id="thank-you" defaultMessage="Thank You!"></FormattedMessage>
                </Typography>
                <Typography variant="h5" color="textSecondary" paragraph>
                {!opened ? 
                <FormattedMessage id="thank-you-title-1" defaultMessage="Your submission was successful."></FormattedMessage> 
                : 
                <FormattedMessage id="thank-you-title-2" defaultMessage="You have already submitted the survey."></FormattedMessage>}
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                {!opened ? 
                <FormattedMessage id="thank-you-description-1" defaultMessage="We appreciate your time filling up the survey. As we promised, your personal information won't be shared with anyone. The data you submitted, will however be used as part of my study case for my dissertation project. If you have any concerns about your response, be free to contact me."></FormattedMessage>
                :
                <FormattedMessage id="thank-you-description-2" defaultMessage="Ok, I get it. You found HackMeDown interesting and you want to try the challenges again. Sadly, I am still collecting data and working on my dissertation. The data need to be accurate so I can get the best feedback possible and improve the platform based on that. As I have collected your email address, I will email you as soon as HackMeDown is open to everyone. Finally, I would like to thank you for your time and effort. I hope you enjoyed the challenges and learnt more about web security and how vulnerabilities can affect every web application."></FormattedMessage>
                }
                </Typography>
                {/* encourage the students to take the final survey */}
                <Typography variant="h6" color="textSecondary" paragraph>
                    {opened ? 
                    <FormattedMessage id="complete-survey-reminder-2" defaultMessage="If you haven't done so, please remember to take the final survey to enter the pool to be one of the 2 winners for a 15£ Amazon Gift card!." ></FormattedMessage>
                    : 
                    <FormattedMessage id="complete-survey-reminder-1" defaultMessage="As part of my dissertation project, I would like to invite you to take the final survey. Your feedback is important to me and will help me to improve the platform according to your input. To thank you for your participation, once you finish with the final survey, you will be added into the poll and hopefuly be among the one of the two lucky winners to receive a 15£ Amazon Gift Card."></FormattedMessage>
                    }
                </Typography>
                <Button
                    href="https://edinburghinformatics.eu.qualtrics.com/jfe/form/SV_4OBjJh6ixlfnTro"
                    target='_blank'
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '0.5rem' }}
                >
                    <FormattedMessage id="final-survey" defaultMessage="Final Survey"></FormattedMessage>
                </Button>
                {/* survey token */}
                {surveyToken ? (
                    <>
                        <Typography variant="h5" color="textSecondary" paragraph style={{ marginTop: '1rem' }}>
                            <FormattedMessage id="your-token" defaultMessage="Your survey token is:"></FormattedMessage>
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            <strong>{surveyToken}</strong>
                        </Typography>
                    </>

                ) : (<></>)}
                {/* challenges completed */}
                <Typography variant="h5" color="textSecondary" paragraph>
                    {completedChallenges.length > 0 ? <FormattedMessage id="completed-challenges" defaultMessage="You have completed the following challenges:"></FormattedMessage> : ""}
                </Typography>
                {completedChallenges.length > 0 ? (
                    completedChallenges.map((challenge, index) => (
                        <Typography key={index} variant="body1" color="textSecondary" paragraph>
                            <strong>{challenge.title}</strong>
                        </Typography>
                    ))
                ) : (<></>)}

                <Typography variant="h5" color="textSecondary">Quizzes</Typography>

                {quiz ? quiz.map((quiz, index) => (
                    <>
                        <Typography variant="h5" color="textSecondary" paragraph>
                            <FormattedMessage id="quiz-title" defaultMessage={quiz.challenge}>{quiz.challenge}</FormattedMessage>
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '20%' }}>Question</TableCell>
                                        <TableCell style={{ width: '20%' }}>Pre Answer</TableCell>
                                        <TableCell style={{ width: '20%' }}>Post Answer</TableCell>
                                        <TableCell style={{ width: '10%' }}>Pre Correct</TableCell>
                                        <TableCell style={{ width: '10%' }}>Post Correct</TableCell>
                                        <TableCell style={{ width: '20%' }}>Solution</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quiz.questions.map((quiz, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ width: '20%' }}>{quiz.text}</TableCell>
                                            <TableCell style={{ width: '20%' }}>{quiz.preAnswer}</TableCell>
                                            <TableCell style={{ width: '20%' }}>{quiz.postAnswer}</TableCell>
                                            <TableCell style={{ width: '10%' }}>
                                                {quiz.preCorrect !== undefined && (
                                                    <>
                                                        {quiz.preCorrect === true ? (
                                                            <CheckCircleIcon style={{ color: 'green' }} />
                                                        ) : (
                                                            <CancelIcon style={{ color: 'red' }} />
                                                        )}
                                                    </>
                                                )}
                                            
                                            </TableCell>
                                            <TableCell style={{ width: '10%' }}>
                                            {quiz.postCorrect !== undefined && (
                                                <>
                                                    {quiz.postCorrect ? (
                                                        <CheckCircleIcon style={{ color: 'green' }} />
                                                    ) : (
                                                        <CancelIcon style={{ color: 'red' }} />
                                                    )}
                                                </>
                                                )}
                                            </TableCell>
                                            <TableCell style={{ width: '20%' }}>{quiz.solution}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )) : (<></>)}
                
                <Button
                    href="/"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '20px' }}
                >
                <FormattedMessage id="back-home" defaultMessage="Back Home"></FormattedMessage>
                </Button>
            </div>
        )}
    </Container>
  );
};

export default ThankYouPage;
