import { createSelector } from 'reselect';

export const getActiveTab = state => {return state.browser.activeTab} ; // Provide a default tab if activeTab is undefined
export const getSelectedBrowser = state => {return state.browser.selectedBrowser} ; // Provide a default browser if selectedBrowser is undefined
// export const getActiveTab = state => {console.log(state); return state.browser.activeTab} ; // Provide a default tab if activeTab is undefined
// export const getSelectedBrowser = state => {console.log({...state.browser}, window.parent == window ? "parent" : "iframe"); return state.browser.selectedBrowser} ; // Provide a default browser if selectedBrowser is undefined

export const getTabs = createSelector(
  [(state) => state.browser.tabs],
  (tabs) => tabs
);
export const getPage = createSelector(
  [(state) => state.browser.page, getActiveTab],
  (page, activeTab) => (activeTab && page[activeTab]) ? page[activeTab] : null
);

export const getBrowsersAndTabs = createSelector(
  [(state) => state.browser.browsersAndTabs],
  (browsersAndTabs) => browsersAndTabs
);

export const getUser = createSelector(
  [(state) => state.browser.user, getActiveTab],
  (user, activeTab) => (activeTab && user[activeTab]) ? user[activeTab] : {}
);

export const getParams = createSelector(
  [(state) => state.browser.params, getActiveTab],
  (params, activeTab) => (activeTab && params[activeTab]) ? params[activeTab] : {}
);

export const getUid = createSelector(
  [(state) => state.browser.uid, getActiveTab],
  (uid, activeTab) => (activeTab && uid[activeTab]) ? uid[activeTab] : {}
);

export const getUrl = createSelector(
  [(state) => state.browser.url, getSelectedBrowser, getActiveTab],
  (url, selectedBrowser, activeTab) =>
    (selectedBrowser && activeTab && url[selectedBrowser] && url[selectedBrowser][activeTab]) 
    ? url[selectedBrowser][activeTab] 
    : ''
);

export const getDomain = createSelector(
  [(state) => state.browser.domain, getActiveTab],
  (domain, activeTab) => (activeTab && domain[activeTab]) ? domain[activeTab] : ''
);

export const getUndoHistory = createSelector(
  [(state) => state.browser.undoHistory, getActiveTab],
  (undoHistory, activeTab) => (activeTab && undoHistory[activeTab]) ? undoHistory[activeTab] : []
);

export const getUndoHistoryPointer = createSelector(
  [(state) => state.browser.undoHistoryPointer, getActiveTab],
  (undoHistoryPointer, activeTab) => (activeTab && undoHistoryPointer[activeTab]) ? undoHistoryPointer[activeTab] : 0
);

export const getSession = createSelector(
  [(state) => state.browser.session, getSelectedBrowser, getActiveTab],
  (session, selectedBrowser, activeTab) =>
    (selectedBrowser && activeTab && session[selectedBrowser] && session[selectedBrowser][activeTab]) 
    ? session[selectedBrowser][activeTab] 
    : { status: false }
);

export const getScriptValid = createSelector(
  [(state) => state.browser.scriptValid],
  (scriptValid) => scriptValid
);
export const getChallengeData = createSelector(
  [(state) => state.browser.challengeData],
  (challengeData) => challengeData
);
export const getRestart = createSelector(
  [(state) => state.browser.restart],
  (restart) => restart
);
export const getAllUrls = createSelector(
  [(state) => state.browser.allUrls],
  (allUrls) => allUrls
);
export const getBrowsers = createSelector(
  [(state) => state.browser.browsers],
  (browsers) => browsers
);