import axios from "axios";
import { store } from "store.js";

const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || "http://149.28.107.216:5001",
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json', // Or 'application/x-www-form-urlencoded' depending on your setup
  }
});

// console.log(API)

API.interceptors.response.use((response) => {
  console.log("Response received:", response, response.config.url);
  return response;
}, (error) => {
  console.error("Error in Axios response:", error);
  return Promise.reject(error);
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  // Get language from localStorage
  const locale = localStorage.getItem('locale') || 'en'; // Default to 'en' if no locale is found
  req.headers['Accept-Language'] = locale;

  // Get the current Redux state
  const state = store.getState();

  if(state){
    if(state.browser){
      const selectedBrowser = state.browser.selectedBrowser;
      const activeTab = state.browser.activeTab;

      // console.log(selectedBrowser, activeTab, "AXIOS");
    
      if (selectedBrowser) {
        req.headers['x-browser'] = selectedBrowser;
      }
    
      if (activeTab) {
        req.headers['x-active-tab'] = activeTab;
      }
    }
  }

  return req;
});

const subdomainAPI = (subdomain) => axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || `http://${subdomain}.${process.env.REACT_APP_SERVER_DOMAIN || "149.28.107.216:5001"}`,
  withCredentials: true,
});

const config = {headers: {
  'Content-Type': 'multipart/form-data'
},}
 
// challenge Auth
export const challengeLogin = (formData) => API.post(`/challenge/login`, formData);
export const scenarioAuth = (info) => API.post(`/challenge/session`, info);
export const endScenarioAuth = (info) => API.post(`/challenge/session/destroy`, info);

// check if page can be accessed
export const pageIsAuth = (info) => API.post(`/challenge/page/auth`, info);

// challenge Users
export const fetchUsers = (query) => API.post(`/challenge/users`, query);
export const fetchProfile = (username) => API.post(`/challenge/profile`, username);
export const updateUser = (info) => API.post(`/challenge/user/update`, info);

// challenge Utilities
export const resetLevel = (username) => API.post(`/challenge/reset`, username);

// challenge Bank Scenario
export const getBalance = (formData) => API.post(`/challenge/balance`, formData);
export const getTransfers = (formData) => API.post(`/challenge/transfers`, formData);
export const bankTransfer = (formData) => API.post(`/challenge/transfer`, formData);

// challenge BestSocial Scenario
export const fetchProfileSettings = (info) => API.post(`/challenge/social/profile/settings`, info);
export const fetchPosts = (query) => API.post(`/challenge/social/feed`, query);
export const createPost = (info) => API.post(`/challenge/social/post/create`, info);

// challenge Info
export const getChallengeStatus = (info) => API.get(`/challenge/status`, info);
export const getCompletedChallenges = (info) => API.get(`/challenge/completed`, info);
export const getHint = (info) => API.get(`/challenge/hint`, info);
export const getSolution = (info) => API.get(`/challenge/solution`, info);
export const skipChallenge = (info) => API.get(`/challenge/skip`, info);
export const fetchChallenge = (challenge) => API.post(`/challenge/${challenge}`);
export const generateNextChallenge = () => API.post(`/challenge/generate`);
export const setChallenge = (challenge) => API.post(`/challenge/status/set`, challenge);
export const getAllChallenges = () => API.get(`/challenges`);
export const getTopUsers = () => API.get(`/leaderboard`);

// challenge submit report
export const submitReport = (info) => API.post(`/challenge/report`, info);

// challenge Answers
export const getChallengeAnswers = () => API.get(`/challenge/answers`);
export const setChallengeAnswers = () => API.post(`/challenge/answers/set`,);

export const fetchCookies = (scenario) => API.post(`/challenge`, scenario);

// Platform authentication 
export const signin = (formData) => API.post(`/auth/signin`, formData);
export const signup = (formData) => API.post(`/auth/signup`, formData);
// export const endSession = (scenario) => API.post(`/auth/signup`, scenario);

// change browser
export const changeBrowser = (info) => API.post(`/challenge/browser`, info);
export const changeTab = (info) => API.post(`/challenge/tab`, info);
export const packetSnifferCookieStolen = (info) => API.post(`/challenge/packetsniffer/cookie_stolen`, info);

// attacker
export const getCookies = () => API.post(`/challenge/attacker/cookies`);
// emails
export const fetchEmails = (email) => API.post(`/challenge/emails`, email);
export const createEmail = (email) => API.post(`/challenge/send/email`,email);

// check if cookies are added
export const cookiesUpdatedCheck = () => API.post(`/challenge/attacker/cookies/updated`);

// packet sniffer
export const getPackets = () => API.post(`/challenge/packet-sniffer/packets`);

// phishing
export const fetchStolenCredentials = () => API.post(`/challenge/phishing/credentials`);
export const clickedOnLink = (info, config) => API.post(`/challenge/phishing/linkclicked`, info, config);
export const stolenCreditCardsCheck = (info) => API.post(`/challenge/phishing/credit-cards-check`, info);

// ecommerce
export const getProducts = (query) => API.post(`/challenge/ecommerce/products`, query);
export const getProduct = (slug) => API.get(`/challenge/ecommerce/product/${slug}`, slug);
export const openPayment = (info) => API.post(`/challenge/ecommerce/payment`, info);
export const paymentSubmit = (info) => API.post(`/challenge/ecommerce/payment/submit`, info);

// check if packets are added
export const packetsUpdatedCheck = () => API.post(`/challenge/packet-sniffer/packets/updated`);

export const hasProgress = () => API.post(`/challenge/has-progress`);
export const submitSurvey = (info) => API.post(`/survey/submit`, info);

export const challengesCompleted = () => API.post(`/survey/completed`);
export const surveysCompleted = () => API.post(`/survey/thank-you`);
export const fetchSurveyQuestions = () => API.post(`/survey/questions`);

// check if xss script works
export const xssScriptValidity = (info) => API.post(`/challenge/xss/check`, info);

export const openArticle = (category, slug) => API.post(`/challenge/article/${category}/${slug}`);
// blog

//questionnaire
export const fetchQuestions = (challenge) => API.post(`/challenge/questionnaire`, challenge);
export const submitAnswer = (info) => API.post(`/challenge/questionnaire/submit`, info);
export const fetchAnsweredQuestions = () => API.post(`/challenge/questionnaire/answered`);

export const fetchArticles = (info) => subdomainAPI("blog").post(`/`, info);
export const fetchArticlesByCategory = (category) => subdomainAPI("blog").post(`/${category}`);
export const fetchArticle = (category, slug) => subdomainAPI("blog").post(`/${category}/${slug}`);

export const verifyEmail = (token) => API.post('/verify-email', { token })
export const sendVerificationEmail = () => API.post('/send-email-verification');
export const forgotPassword = (email) => API.post('/auth/forgot-password', email); 
export const resetPassword = (password, confirmPassword, token, userId) => API.post('/auth/reset-password', password, confirmPassword, token, userId); 
