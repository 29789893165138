import React, { useEffect, useState } from 'react';
import { Outlet, Link, useParams, Form } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link as MuiLink,
  Breadcrumbs,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { fetchArticlesByCategory } from 'actions/blog.js';
import { FormattedMessage } from 'react-intl';


const Blog = () => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const { category } = useParams();

  // Fetch blog posts from the API
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticlesByCategory(category)).then((result) => {
      console.log(result);
      if(!result){
        setError("An uknown error occurred while fetching articles.");
      }
      else{
        if (result.error) {
          setError(result.error);
        } else {
          setData(result);
        }
      }
    });
  }, [])

  const convertSlugToTitle = (slug) => {
    const words = slug.split('-');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  };

  return (
    <Container className={classes.root}>

      <Grid xs className='mb-3'  >

      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <MuiLink component={Link} to="/" color="inherit">
          <FormattedMessage id="home" defaultMessage="Home" ></FormattedMessage>
        </MuiLink>
        <MuiLink component={Link} to={`/${data.name}`} color="inherit">
          {data.name}
        </MuiLink>
      </Breadcrumbs>
      </Grid>

      <Typography variant="h4" gutterBottom>
        {convertSlugToTitle(category)}
      </Typography>
      <Typography variant="body1" paragraph>
        {data.description}
      </Typography>

      <Grid container spacing={3}>
        {/* Example blog post cards */}
        {data.posts && data.posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post}>
            <Card className={classes.card}>
              <CardMedia
                component="img"
                alt={`${post.title}`}
                height="160"
                image={`https://source.unsplash.com/160x160/?security,cyber,technology`}
                className={classes.media}
              />
              <CardContent className={classes.content}>
                <Typography variant="h6" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Description of the blog post content. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit.
                </Typography>
                <Link to={`/${post.category}/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <FormattedMessage id="read-more" defaultMessage="Read more" ></FormattedMessage>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Outlet for nested routes */}
      <Outlet />
    </Container>
  );
};

export default Blog;
