import React, { useState } from "react";
import { Container, Grow, Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Copyright from "./Copyright/Copyright.js";

const Footer = () => {
  const theme = useTheme();
const classes = useStyles(theme);;
  const [currentDate, setCurentDate] = useState(new Date().getFullYear());

  return (
    <Box className="floating-footer text-center" pb={2} pt={2} style={{ zIndex: 1 }}>
      {/* Add Privacy Policy and Cookies Policy Links */}
      <Box mt={2}>
         <Link component={`${process.env.REACT_APP_PUBLIC_URL}/privacy-policy`} to="privacy-policy" color="inherit" target="_blank" style={{ marginRight: '1rem' }}>
              <FormattedMessage
                id="privacy"
                defaultMessage="Privacy Policy"
              />
            </Link>
            <Link  component={`${process.env.REACT_APP_PUBLIC_URL}/cookies-policy`} to="cookies-policy" color="inherit" target="_blank">
              <FormattedMessage
                id="cookies"
                defaultMessage="Cookies Policy"
              />
            </Link>
      </Box>
      <Copyright />

    </Box>
  );
};

export default Footer;
