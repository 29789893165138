import { GET_CHALLENGE_STATUS, SET_CHALLENGE_STATUS, UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";

const initialState = {
    data: {},
    loading: false,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CHALLENGE_STATUS:
            // console.log(state, action?.payload, "hiiiikfjs;k;fkgjsgfjdskgfsjkgjgsjgfdj;sgi");
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: action?.payload, loading: false, error: null};
        case SET_CHALLENGE_STATUS:
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: action?.payload, loading: false, error: null};
        case UPDATE_CHALLENGE_STATUS:
            // console.log(state, action?.payload, "hiiiikfjs;k;fkgjsgfjdskgfsjkgjgsjgfdj;sgi");

            if(Object.keys(state.data).length > 0){
                if(state.data.find((step) => step.step == action?.payload))
                {
                    state.data.find((step) => step.step == action?.payload).isComplete = 1;
                }
            }
    
                console.log(window.parent, window)
                if(window.parent !== window){
                    window.parent.postMessage({state: action}, "*");
                }
                return {data: state.data, loading: false, error: null};
        default:
            // console.log(action?.payload, state)
            return state;
            
    }
};
