import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
  loginContainer: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  text: (theme) => ({
    color: theme.textColor
  }),
  bgSoft: (theme) => ({
      backgroundColor: theme.bgSoft,
  }),
  bg: (theme) => ({
      backgroundColor: theme.bg,
  }),
  textSoft: (theme) => ({
      color: theme.textColorSoft
  }),
  textField: (theme) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.mode === 'dark' ? '#444' : '#b0b0b0', // Dynamic border color
      },
      '&:hover fieldset': {
        borderColor: theme.palette.mode === 'dark' ? '#90caf9' : '#007bff', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.mode === 'dark' ? '#90caf9' : '#007bff', // Border color when focused
      },
    },
    '& labe.MuiInputLabel-root': {
        color: theme.palette.mode === 'dark' ? '#90caf9' : '#007bff', // Border color when focused
    },
    '& input': {
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Text color
    },
    '& input::placeholder': {
      color: theme.palette.mode === 'dark' ? '#aaaaaa' : '#888888', // Placeholder color
      opacity: 1,
    },
    '& label': {
      color: theme.palette.mode === 'dark' ? '#bbbbbb' : '#000000', // Label color
    },
    '& label.Mui-focused': {
      color: theme.palette.mode === 'dark' ? '#90caf9' : '#007bff', // Label color when focused
    },
    '& .MuiInput-underline:after': {
      borderBottom:  theme.palette.mode === 'dark' ? "1px solid #fff"  :"1px solid rgba(0, 0, 0, 0.42)",
    },
  }),
  contentEditable: (theme) => ({
    border: `1px solid ${theme.palette.mode === 'dark' ? '#444' : '#b0b0b0'}`, // Editable border color
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Text color for contentEditable
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#ffffff', // Background color
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#90caf9' : '#007bff', // Border color on hover
    },
    textAlign: 'left',
    whiteSpace: 'pre-wrap', // Ensures text wraps within the container
    wordBreak: 'break-word', // Break words when they are too long
    overflowWrap: 'break-word', // Break lines within words to prevent overflow
    width: '100%', // Prevent horizontal scrolling
    maxWidth: '100%', // Ensures it doesn't exceed the parent width
    overflow: 'auto', // Enable scrolling for overflow
    padding: '8px', // Optional: Add padding for better text appearance
    minHeight: '100px', // Optional: Minimum height of the content area
    borderRadius: theme.shape.borderRadius, // Optional: Add border radius
  }),
  iconButton: (theme) => ({
      color: theme.textColor, // Use theme text color for the button
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: theme.bg, // Dynamic hover color based on theme mode
        color: theme.textColor, // Change icon color on hover (optional)
      },
  }),
}));
