import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { FormattedMessage } from 'react-intl';

const ScenarioInstructionsModal = (props) => {

  const {isOpen, setIsOpen, setText} = useContext(ModalContext);

  const {content, title} = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const contentRef = useRef(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
      
      
      const timeoutId = setTimeout(() => {
      
        if(!contentRef.current) return;
        if(isOpen !== "instructions") return;

        if (contentRef.current) {
          const handleScroll = () => {
            const isScrollable =
              contentRef.current.scrollHeight - contentRef.current.scrollTop > contentRef.current.clientHeight;
            console.log( contentRef.current.scrollHeight + contentRef.current.scrollTop, contentRef.current.clientHeight)
            setShowScrollIndicator(isScrollable);
          };

          handleScroll();
    
          contentRef.current.addEventListener('scroll', handleScroll);
    
          return () => {
            contentRef.current.removeEventListener('scroll', handleScroll);
          };
        }
      }, 0); // Delay of 0 milliseconds
    
      return () => clearTimeout(timeoutId);
  }, [contentRef, isOpen]);

  const scrollToBottom = () => {
    contentRef.current.scrollTop = contentRef.current.scrollHeight;
  };

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  return (
    <div>
        <Modal 
            open={isOpen === "instructions"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box  className={classes.box}>
                <h2 id="modal-title" className={classes.text}><FormattedMessage id="Scenario Instructions"  defaultMessage="Scenario Instructions" /></h2>
                <Box className={classes.textContent} ref={contentRef}>
                    <Typography variant="p" className={classes.text}>
                    {content && content.split('\n').map((line, index) => (
                        <>
                          {index == 0 ? (<><React.Fragment key={"title"}><h5>{title}</h5></React.Fragment><br /></>) : (<></>)}
                          <React.Fragment key={index}>
                              {line}
                              <br />
                          </React.Fragment>
                        </>
                    ))}  
                    </Typography>
                </Box>
                <Box className="w-100 d-flex justify-content-center align-items-center">
                        {showScrollIndicator ? (
                        <IconButton
                            className={classes.scrollButton}
                            onClick={scrollToBottom}
                        >
                        <ArrowDownwardIcon className={classes.text} />
                    </IconButton>
                        ) : <></>}
                </Box>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    <FormattedMessage id="Got it"  defaultMessage="Got it" />
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default ScenarioInstructionsModal;