import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Paper, CardMedia, Grid } from '@mui/material';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from 'actions/ecommerce';
import { Button } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from '../styles.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { FormattedMessage } from 'react-intl';

const Product = () => {

const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const [product, setProduct] = useState({});
    const [error, setError] = useState(null);

    const theme = useTheme();
  const classes = useStyles(theme);

    const dispatch = useDispatch();

    const {data} = useSelector((state) => state.ecommerce);

    useEffect(() => {

        if (!params.slug) return;

        if(!data.products) return;

        if(params.deliver_to){
            const deliver_to = document.getElementById('deliver_to');
            // deliver_to.innerHTML = `${params.deliver_to}`;
        }
        
        if (data.products.length === 0 && error === null)
        {
          dispatch(getProduct({slug: params.slug})).then((response) => {
              if (response && response.product) {
                  setProduct(response.product);
              }
              else{
                setError("Error fetching products");
              }
          })
          .catch((error) => {
              console.error(error);
          });
        }
        else{
          const productFound = data.products.find((product) => product.slug === params.slug);
          if(productFound){
            setProduct(productFound);
          }
          else{
            setProduct(null);
            setError("Product not found");
          }
        }
    }, [params, data]);

    const handleBuy = (slug) => {
      dispatch({ type: 'UPDATE_PAGE', payload: 'payment' });
      dispatch({ type: 'UPDATE_URL', payload: domain+`/payment?slug=${slug}&deliver_to=${encodeURIComponent(data.authUser.address)}` });
    }

  return (
    <Container className={'my-3'}>
      {error && <Typography variant="h6" color="error" style={{padding: "16px"}} gutterBottom>{error}</Typography>
      }
      {product && <>
        <Typography variant="h4" className={`${classes.textSoft}`} gutterBottom>
          {product.title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CardMedia component="img" height="auto" image={product.image} alt={product.title} />
          </Grid>
          <Grid item xs={12} md={6}>
              <div className={`${classes.bgSoft} ${classes.text} rounded shadow`} style={{ padding: '16px' }}>

              <Typography variant="body1" paragraph>
                <strong><FormattedMessage id="description" defaultMessage="Description" ></FormattedMessage>:</strong> {product.description}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong><FormattedMessage id="stock" defaultMessage="Stock" ></FormattedMessage>:</strong> {product.stock}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong><FormattedMessage id="price" defaultMessage="Price" ></FormattedMessage>:</strong> ${product.price}
              </Typography>
              <Typography variant="body1">
                <strong><FormattedMessage id="upload-date" defaultMessage="Upload Date" ></FormattedMessage>:</strong> {new Date(product.createdAt).toLocaleString()}
              </Typography>
              {/* <p id="deliver_to">

              </p> */}
              {/* <Typography variant="body2" color="textSecondary" id="deliver_to">
                
              </Typography> */}
              <Button variant="contained" color="primary" onClick={() => handleBuy(product.slug)}>
              <FormattedMessage id="buy" defaultMessage="Buy" ></FormattedMessage>
              </Button>
              </div>
          </Grid>
        </Grid>
      </>}
    </Container>
  );
};

export default Product;
