import { makeStyles } from "@material-ui/core/styles/index.js";
import { deepPurple } from "@material-ui/core/colors/index.js";

export default makeStyles((theme) => ({
    container: {
      padding: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
    table: {
      minWidth: 650,
    },
    headerCell: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    tableContainer: {
      maxHeight: 500,
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '50vh',
    },
    bg: (theme) => ({
      backgroundColor: theme.bg,
    }),
    bgSoft: (theme) => ({
      backgroundColor: theme.bgSoft,
    }),
    heading: (theme) => ({
      color: theme.logo,
      textDecoration: "none",
    }),
    text: (theme) => ({
      color: theme.textColorSoft
    }),
    flag: {
      width: '30px',  // Adjust size based on your needs
      height: '20px',
      marginRight: theme.spacing(1),
    },
  }));