import { GET_CHALLENGE, SET_CHALLENGE } from "../constants/actionTypes.js";

const initialState = {
    data: {},
    loading: false,
    error: null,
}

export default (challenge = initialState, action) => {
    switch (action.type) {
        case GET_CHALLENGE:
            // send message to parent
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return { data: { ...challenge.data, ...action.payload }, loading: false, error: null };
        case SET_CHALLENGE:
            console.log("SET_CHALLENGE", action.payload)
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return { data: { ...challenge.data, ...action.payload }, loading: false, error: null };
        default:
            return challenge;
    }
};
