import { VERIFY_EMAIL, VERIFY_EMAIL_ERROR } from "../constants/actionTypes.js";
import * as api from "../api/index.js";

export const verifyEmailAction = (token) => async (dispatch) => {
    try{
        const { data } = await api.verifyEmail(token);

        return data;

    }
    catch(error){
        dispatch({ type: VERIFY_EMAIL_ERROR, payload: error.message });
    }
}

export const sendVerificationEmail = () => async (dispatch) => {
    try{
        const { data } = await api.sendVerificationEmail();

        return data;

    }
    catch(error){
        dispatch({ type: VERIFY_EMAIL_ERROR, payload: error.message });
    }
}
