// src/LoginPage.js
import React, { useContext, useDebugValue, useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {bankScenarioAuth} from "../../../../actions/challenge.js";
 
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { SolutionsContext } from '../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from "selectors/browser";
import AuthInput from 'Components/Input/AuthInput.js';
import { FormattedMessage } from 'react-intl';

const LoginPage = (props) => { 

  const {title, platform}  = props;

  const {solutions, setSolutions} = useContext(SolutionsContext);
  const [loginCheckDone, setLoginCheckDone] = useState(false);


  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [searchParams, setSearchParams] = useSearchParams();

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

  let urlFromBrowser = "";

  if(window.parent.document.getElementsByClassName("browser-url-input")[0]){
    urlFromBrowser = window.parent.document.getElementsByClassName("browser-url-input")[0].getElementsByTagName("input")[0].value;
  }

  useEffect(() => {
    if(searchParams.get('active')){
      dispatch({type: 'UPDATE_ACTIVE_TAB', payload: searchParams.get('active')})
    }
    if(searchParams.get('browser')){
      dispatch({type: 'UPDATE_SELECTED_BROWSER', payload: searchParams.get('browser')})
    }

    // console.log(searchParams.get('active'),searchParams.get('browser'))
  },[searchParams])

  useEffect(() => {
    // console.log('react sucks', isAuthenticated)
    if (isAuthenticated) {
      // Redirect or perform actions for an authenticated user
      console.log('User is already authenticated');
      // For example, you can redirect to another page:
      // history.push('/dashboard');
    }
  },[isAuthenticated]);

  const setUpCookies = (data) => {
    // set cookies to expire in the future
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now

    if(data.auth.sessionId){
      document.cookie = `session_id=${data.auth.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;

      localStorage.setItem('session_id', data.auth.sessionId);
      localStorage.setItem(`${selectedBrowser}_${activeTab}`, data.auth.session);
    }

  }

  const afterLogin = (data) => {
    setLoginLoading(false);
    // console.log(data)
    if(data){
      if(data.error){
        setError(data.error)
      }
      if (data.auth) {
        // setSession
        // console.log('hi',{...data.auth, status: true})

        // set cookies for scenario and session cookie
        setUpCookies(data);

        if(data.auth.user.role === "admin"){
          dispatch({type: 'UPDATE_PAGE', payload: "admin"})
          dispatch({type: 'UPDATE_URL', payload: `https://www.${activeTab}.com/admin`})
        }
        else{
          dispatch({type: 'UPDATE_PAGE', payload: "menu"})
          dispatch({type: 'UPDATE_URL', payload: `https://www.${activeTab}.com`})
        }
        // console.log(data)
        dispatch({type: 'UPDATE_SESSION', payload: {...data.auth, status: true}});

        // set login check to done

        // setUrl(data.auth);
        console.log('Data submitted successfully');
      } else {
        console.error('Error submitting data');
      }
    }
  };


  // called as soon as the page loads. Should only be called if parameters exist

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [level, setLevel] = useState(1);
  const [loginLoading, setLoginLoading] = useState();

  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = useStyles(theme);

  const selectors = useSelector((state) => state);

  useEffect(()=>{
 
    if (!solutions) return;
    
    const loginAnswers = solutions.login ?? solutions.register;
    // console.log(solutions)
    if(!loginAnswers) return;

      setUsername(loginAnswers ? (loginAnswers.username || "") : "");
      setPassword(loginAnswers ? (loginAnswers.password || "") : "");
  }, [solutions])


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoginLoading(true);
    setError(null)

    try {  
      // Perform AJAX request here

      dispatch(bankScenarioAuth({username,password,level, scenario: activeTab, browser: selectedBrowser, url: urlFromBrowser, params: params})).then(data=>{

        afterLogin(data);
      });

      // Handle response
    } catch (error) {
      console.error('AJAX error: ', error);
    }
  };

    return (
      <Container maxWidth="sm" className="d-flex justify-content-center h-100 flex-column align-items-center">
      <h3 className={classes.text}>
        <FormattedMessage id={title} defaultMessage={title} values={{platform}} ></FormattedMessage>
      </h3>
      <form className="w-75 d-flex justify-content-center align-items-center flex-column" onSubmit={handleSubmit}>
        <div className="w-100 m-2">
          <AuthInput
            label={<FormattedMessage id="username" defaultMessage="Username" ></FormattedMessage>}
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
          
        <div className="w-100 m-2">
        <AuthInput
          label={<FormattedMessage id="password" defaultMessage="Password" ></FormattedMessage>}
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        </div>
        <div className='w-100 d-flex justify-content-end'>
        <Button type="submit" variant="contained" color="primary">
        <FormattedMessage id="login" defaultMessage="Login" ></FormattedMessage>
        </Button>
        </div>
        {error && 
          <Typography className={`${ classes.text} text-center mt-2`}><FormattedMessage id={error} defaultMessage={error} ></FormattedMessage></Typography>
        }
        {loginLoading && 
          <Typography className='mt-2'>
            <CircularProgress />
          </Typography>
          }
      </form>
    </Container>
    );
};

export default LoginPage;