// src/pages/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {verifyEmailAction} from '../../actions/email.js';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";

export const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

const theme = useTheme();
  const classes = useStyles(theme);

  // const history = useHistory();

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      console.log(token)

      dispatch(verifyEmailAction(token)).then(data =>{

        console.log(data)
  
        if(data.isVerified){
          setMessage('Email verified successfully!');
        }else{
          setMessage('Email verification failed. Please try again.');
        }
        setLoading(false);
      })
 
      // try {
      //   const response = await axios.post('/verify-email', { token });
      //   if (response.status === 200) {
      //     setMessage('Email verified successfully!');
      //   } else {
      //     setMessage('Email verification failed. Please try again.');
      //   }
      // } catch (error) {
      //   setMessage('Email verification failed. Please try again.');
      // } finally {
      //   setLoading(false);
      // }
    };

    verifyEmail();
  }, [location.search]);

  const handleLoginRedirect = () => {
    window.location.href = '/login';
  };

  return (
    <div className={classes.text} style={{ textAlign: 'center', padding: '50px' }}>
      {loading ? <p><FormattedMessage id="Verifying" defaultMessage="Verifying" />...</p> : <p><FormattedMessage id={message} defaultMessage={message} /></p>}
      {!loading && (
        <Button variant="contained" color="primary" onClick={handleLoginRedirect}>
          <FormattedMessage id="home" defaultMessage="Home" />
        </Button>
      )}
    </div>
  );
};
