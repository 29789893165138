import { GET_STOLEN_PACKETS, GET_STOLEN_PACKETS_ERROR } from "../constants/actionTypes.js";

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export default (bankBalance = initialState, action) => {
  switch (action.type) {
    case GET_STOLEN_PACKETS:
      if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }  
      return {data: action?.payload, loading: false, error: null};
    case GET_STOLEN_PACKETS_ERROR:
      if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }  
      return {data: [], loading: false, error: action?.payload};
    default:
      return bankBalance;
  }
};
