import { useReducer , createContext} from "react";

export const syncStates = (data) => async (dispatch) => {
    // send the state to the child iframe window
  try{
    // dispatch all states except browser
    dispatch(data)
  }
  catch(e){
    console.log(e);
  }
}