import { AUTH, SEND_VERIFICATION_CODE, LOGOUT, NOT_AUTH, AUTH_ERROR } from "../constants/actionTypes.js";

export default (state = null, action) => {
  switch (action.type) {
    case AUTH:
      if (Object.keys(action?.data).length) {
        localStorage.setItem("profile", JSON.stringify({ ...action?.data }));

        const profile = localStorage.getItem('profile');
        const auth = JSON.parse(profile)["result"]["username"];
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
        document.cookie = `auth=${auth}; expires=${expirationDate.toUTCString()}; path=/;`;
      }
      return action?.data;
    case NOT_AUTH:
      if (Object.keys(action?.data).length) {
        localStorage.clear();
        document.cookie = `auth=; path=/;`;
      }
      return null;
    case AUTH_ERROR:
      if (Object.keys(action?.data).length) {
        localStorage.clear();
        document.cookie = `auth=; path=/;`;
      }
      return null;
    case LOGOUT:
      localStorage.clear();
      document.cookie = `auth=; path=/;`;
      return null;
    default:
      if(localStorage.getItem("profile")){
        // set cookie
        const profile = localStorage.getItem('profile');
        const profileParsed = JSON.parse(profile)
        const auth = profileParsed["result"]["username"];
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
        document.cookie = `auth=${auth}; expires=${expirationDate.toUTCString()}; path=/;`;
        
        return profileParsed;
      }
      return state;
  }
};
