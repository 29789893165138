import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { getProfileSettings, updateUser } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { useTheme } from "styled-components";
import useStyles from "./styles.js";

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import AuthInput from 'Components/Input/AuthInput';
import { FormattedMessage } from 'react-intl';

const Settings = () => {
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  
  const [error, setError] = useState(null);
  const [noUsersError, setNoUsersError] = useState(null);

  const theme = useTheme();
  const classes = useStyles(theme);

  const isMounted = useRef(false); // skipping first useEffect render
  // const [user, setUser] = useState({});

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.socialMedia);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSave = () => {
    // Handle the logic to save the updated profile settings
    // console.log('Name:', name);
    // console.log('Email:', email);

    dispatch(updateUser(
      {
        data: {
          name: name, 
          email: email, 
          username: username, 
          currentPassword: currentPassword, 
          newPassword: newPassword
        }, 
        id: userId
      }
    )).then((result) => {
      if(result.error){
        setError(result.error)
      }
      else{
        setError(result.message);
      }
    })
  };

  // useEffect(() => {
  //   if (!uid) return;


  //   // setUser(session.user);
  //   // setUrl(domain+"/profile/edit?uid="+session.user.username);
  // }, [uid]);

  useEffect(() => {

    // if (!data.profile) return;
    
    if (!session.status) return;
    if (Object.keys(params).length === 0 ) return;
    
    
      // console.log("uid", params, uid, url, params.uid);
      const username = params.uid;

        dispatch(getProfileSettings({username: username, scenario: activeTab, url: url})).then((result) => {
          console.log(result, "error is here");
          setName(null);
          setUserId(null);
          setUsername(null);
          setEmail(null);
          if(result){
            if(result.error){
              setNoUsersError(result.error);
            }
            else{
              setError(null);
              setNoUsersError(null)
              setName(result.name);
              setUserId(result.id);
              setUsername(result.username);
              setEmail(result.email);
            }
          }
          else{
            setNoUsersError("An unexpected error happened. Please try again later.");
          }
          // setUrl(domain+"/profile/edit?uid="+username);
        });
  }, [session, params]);

  return (
    <Container maxWidth="sm" className='px-0 py-3'>
      <Typography variant="h4" className={classes.textSoft} gutterBottom>
        <FormattedMessage id="profile-settings" defaultMessage="Profile Settings" ></FormattedMessage>
      </Typography>
      {!noUsersError ? (
        <>
        <div className="my-3">
          <AuthInput
            label={<FormattedMessage id="name" defaultMessage="Name" ></FormattedMessage>}
            variant="outlined"
            fullWidth
            margin="normal"
            value={name || ''}
            onChange={handleNameChange}
          />
        </div>
        <div className="my-3">
          <AuthInput
            label={<FormattedMessage id="username" defaultMessage="Username" ></FormattedMessage>}
            variant="outlined"
            fullWidth
            margin="normal"
            value={username || ''}
            onChange={handleUsernameChange}
          />
        </div>
        <div className="my-3">
          <AuthInput
            label={<FormattedMessage id="email" defaultMessage="Email" ></FormattedMessage>}
            variant="outlined"
            fullWidth
            margin="normal"
            value={email || ''}
            onChange={handleEmailChange}
          />
        </div>
          <Divider style={{ margin: '16px 0' }} />
          <Typography variant="h5" className={classes.textSoft} gutterBottom>
            <FormattedMessage id="change-password" defaultMessage="Change Password" ></FormattedMessage>
          </Typography>
          <div className="my-3">
            <AuthInput
              label={<FormattedMessage id="current-password" defaultMessage="Current Password" ></FormattedMessage>}
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={currentPassword || ''}
              onChange={handleCurrentPasswordChange}
            />
          </div>
          <div className="my-3">
            <AuthInput
              label={<FormattedMessage id="new-password" defaultMessage="New Password" ></FormattedMessage>}
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newPassword || ''}
              onChange={handleNewPasswordChange}
            />
          </div>
            <Button variant="contained" color="primary" onClick={handleSave}>
              <FormattedMessage id="save" defaultMessage="Save" ></FormattedMessage>
            </Button>
            {error && (
              <Typography>{error}</Typography>
            )}
        </>
      ) : (
        <Typography>{noUsersError}</Typography>
      )}
    </Container>
  );
};

export default Settings;
