import { makeStyles } from "@material-ui/core/styles/index.js";
import { deepPurple } from "@material-ui/core/colors/index.js";

export default makeStyles((them) => ({
    container: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    text: (theme) => ({
      marginBottom: theme.spacing(2),
    color: theme.textColorSoft
    }),
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  heading: (theme) => ({
    color: theme.logo,
    textDecoration: "none",
  }),
}));