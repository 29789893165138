import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import useStyles from "../styles.js";
import { BankAuthContext, transfer } from '../../../../../../actions/bankScenario.js';
import { SolutionsContext } from '../../../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { FormattedMessage } from 'react-intl';
import AuthInput from 'Components/Input/AuthInput.js';

const Transaction = (props) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  
  const dispatch = useDispatch();
  
  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

  const {solutions, setSolutions} = useContext(SolutionsContext);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(()=>{
    const unionAnswer = solutions.transfer;
    if(unionAnswer){
      accountNumber(unionAnswer ? (unionAnswer.accountNumber || "") : "");
      amount(unionAnswer ? (unionAnswer.amount || "") : "");
      description(unionAnswer ? (unionAnswer.description || "") : "");
    }

  }, [solutions])

  const handleTransaction = () => {
    // Handle the transaction logic here
    // You can send the data to your backend or simulate the transaction process

    if(session.user)
    {
      setIsDisabled(true);
      setError(null);
      setSuccess(null);
      dispatch(transfer({
        accountNumber,
        fromAccountNumber: session.user.accountNumber,
        sortCode,
        amount,
        description,
      })).then(result => {
        // console.log(result)
        if(!result.error){
          setSuccess("Transfer made successfully");
        }
        else{
          setError(result.error);
        }
        setIsDisabled(false);
      });
      
      setAccountNumber('');
      setSortCode('');
      setAmount('');
      setDescription('');
    }
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container maxWidth="md" className="mb-3">
      <div  className={classes.text}>

      <Typography variant="h4" align="center" style={{ margin: '20px' }}>
        <FormattedMessage id="Transfer Money" defaultMessage="Transfer Money" ></FormattedMessage>
      </Typography>
      <div className="mb-3">

          <AuthInput
            fullWidth
            required
            label={<FormattedMessage id="Recipient Account Number" defaultMessage="Recipient Account Number" ></FormattedMessage>}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          {/* <TextField
            fullWidth
            label="Recipient Sort Code"
            value={sortCode}
            onChange={(e) => setSortCode(e.target.value)}
            style={{ marginBottom: '20px' }}
          /> */}
          <br></br>
          <br></br>
          <AuthInput
            fullWidth
            required
            label={<FormattedMessage id="Amount (GBP)" defaultMessage="Amount (GBP)" ></FormattedMessage>}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          <br></br>
          <br></br>
          <AuthInput
            fullWidth
            label={<FormattedMessage id="Description" defaultMessage="Description" ></FormattedMessage>}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
      </div>
      <div>
        {error ? <Typography><FormattedMessage id={error} defaultMessage={error} ></FormattedMessage></Typography> : null}
        {success ? <Typography><FormattedMessage id={success} defaultMessage={success} ></FormattedMessage></Typography>: null}
        {isDisabled && <Typography><FormattedMessage id="Pocessing" defaultMessage="Processing" ></FormattedMessage>...</Typography>}

      </div>

      </div>

      <div className='w-100 d-flex justify-content-end'>

        <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleTransaction}>
        <FormattedMessage id="Transfer" defaultMessage="Transfer" ></FormattedMessage>
        </Button>
        <Button variant="contained"  color="primary" style={{marginLeft: "1rem"}}  onClick={() => dispatch({type: "UPDATE_URL", payload:"https://www.bank.com"})}>
        <FormattedMessage id="back" defaultMessage="Back" ></FormattedMessage>
        </Button>
      </div>
      {/* Additional relevant information or components can be added here */}
    </Container>
  );
};

export default Transaction;