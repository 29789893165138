import React from 'react';
import { Container, Button, Typography, Grid, Box } from "@material-ui/core";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { Link, useNavigate } from 'react-router-dom';
import videoBg from "../../assets/Digital_World.mov";
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import Copyright from 'Components/Footer/Copyright/Copyright.js';
import demoVideo from '../../assets/demo.mov'; // Import the demo video

const Home = () => { 
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();

  return (
    <>
    <Helmet>
      {/* Basic SEO */}
      <title>Home | Hackmedown</title>
      <meta name="description" content="Master web security by solving real-world challenges!" />
      <link rel="canonical" href="https://hackmedown.com/" />

      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:title" content="Home | Hackmedown" />
      <meta property="og:description" content="Master web security by solving real-world challenges!" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://hackmedown.com/" />
      <meta property="og:image" content="https://hackmedown.com/images/og-image.jpg" />
      <meta property="og:image:alt" content="Hackmedown - Master web security by solving real-world challenges!" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Home | Hackmedown" />
      <meta name="twitter:description" content="Master web security by solving real-world challenges!" />
      <meta name="twitter:image" content="https://hackmedown.com/images/twitter-image.jpg" />
      <meta name="twitter:image:alt" content="Hackmedown - Master web security by solving real-world challenges!" />
      <meta name="twitter:site" content="@yourtwitterhandle" />

      {/* Structured Data for Rich Snippets */}
      <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://hackmedown.com/",
            "name": "Hackmedown",
            "description": "Master web security by solving real-world challenges!",
            "publisher": {
              "@type": "Organization",
              "name": "Hackmedown"
            }
          }
          `}
      </script>

      {/* Additional SEO Tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="Hackmedown Team" />
      <meta name="robots" content="index, follow" />
      <link rel="icon" href="https://hackmedown.com/favicon.ico" />
  </Helmet>
    <div className={classes.root}>
      {/* Hero Section with Video Background */}
      <div className={classes.heroSection}>
        <video src={videoBg} className={classes.video} autoPlay loop muted />
        <div className={classes.overlay}></div>
        <div className={classes.heroContent}>
          <Typography variant="h3" className={classes.heroTitle}> 
            <FormattedMessage id="hero-title" defaultMessage="HackMeDown" />
          </Typography>
          <Typography variant="h6" className={classes.heroSubtitle}>
            <FormattedMessage id="hero-subtitle" defaultMessage="Learn about web security attacks through real-world examples" />
          </Typography>
          <Button className="m-2" variant="contained" color="primary" onClick={() => navigate("/login")}>
            <FormattedMessage id="start-btn" defaultMessage="Get Started" />
          </Button>
          <Button className="m-2" variant="contained" color="secondary" onClick={() => navigate("/challenges")}>
            <FormattedMessage id="Challenges" defaultMessage="Challenges" />
          </Button>
        </div>
      </div>
 
      {/* Features Section */}
      <Container className={classes.featuresSection}>
      <Grid container className='justify-content-center' spacing={4}>
      <Grid item xs={11}>
        <Typography variant="h4" className={classes.sectionTitle}>
          <FormattedMessage id="features-title" defaultMessage="Why HackMeDown?" />
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.featureTitle}>
              <FormattedMessage id="feature-1-title" defaultMessage="Learn By Doing" />
            </Typography>
            <Typography className={classes.featureDescription}>
              <FormattedMessage id="feature-1-desc" defaultMessage="Challenge yourself through problems and discover the exploitation of a specific web vulnerability." />
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.featureTitle}>
              <FormattedMessage id="feature-2-title" defaultMessage="Varying Difficulties" />
            </Typography>
            <Typography className={classes.featureDescription}>
              <FormattedMessage id="feature-2-desc" defaultMessage="Challenges will be offered in varying difficulties. Beginners and Experienced membbers will be challenged." />
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.featureTitle}>
              <FormattedMessage id="feature-3-title" defaultMessage="Real Scenarios" />
            </Typography>
            <Typography className={classes.featureDescription}>
              <FormattedMessage id="feature-3-desc" defaultMessage="Challenges will present a real-world example with steps provided and your goal is to solve it." />
            </Typography>
          </Grid>
        </Grid>
        </Grid>
        </Grid>
      </Container>

      {/* Challenges Overview Section */}
      <Container className={classes.challengesSection}>
      <Grid container className='justify-content-center' spacing={4}>
      <Grid item xs={11}>
        <Typography variant="h4" className={classes.sectionTitle}>
          <FormattedMessage id="challenges-title" defaultMessage="Our Mission?" />
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.challengeTitle}>
              <FormattedMessage id="challenge-1-title" defaultMessage="Be Aware" />
            </Typography>
            <Typography className={classes.challengeDescription}>
              <FormattedMessage id="challenge-1-desc" defaultMessage="Raise awareness of some of the most common web vulnerabilities currently existing in millions of public websites." />
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.challengeTitle}>
              <FormattedMessage id="challenge-2-title" defaultMessage="Learn by Doing" />
            </Typography>
            <Typography className={classes.challengeDescription}>
              <FormattedMessage id="challenge-2-desc" defaultMessage="We provide with all the tools you need to master each concept. Challenges, detailed articles with examples, hints, variations per vulnerability etc." />
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.challengeTitle}>
              <FormattedMessage id="challenge-3-title" defaultMessage="Fix Vulnerability (coming soon)" />
            </Typography>
            <Typography className={classes.challengeDescription}>
              <FormattedMessage id="challenge-3-desc" defaultMessage="Learn how to fix all variations of the web vulnerabilities presented and discover more advanced ways to exploit further hidden vulnerabilities." />
            </Typography>
          </Grid>
        </Grid>
        </Grid>
        </Grid>
      </Container>

      {/* Demo Section */}
      <Container className={classes.demoSection}>
        <Typography variant="h4" className={classes.sectionTitle}>
          <FormattedMessage id="demo-title" defaultMessage="Demo" />
        </Typography>
        <Typography variant="body1" className={classes.sectionDescription}>
          <FormattedMessage id="demo-description" defaultMessage="Watch our demo video to see how you can learn about web security through hands-on challenges." />
        </Typography>
        <video src={demoVideo} className={classes.demoVideo} controls />
      </Container>

      {/* Blog Section */}
      <Container className={classes.blogSection}>
        <Grid container className='justify-content-center' spacing={4}>
          <Grid item xs={11}>
          <Typography variant="h4" className={classes.sectionTitle}>
            <FormattedMessage id="blog-title" defaultMessage="Educational Articles" />
          </Typography>
          <Typography className={classes.blogDescription}>
            <FormattedMessage id="blog-desc" defaultMessage="Are you interested to find out what are the most common vulnerabilities? Our goal is to explain every single vulnerability and its variations in the simplest way possible, bundled with examples." />
          </Typography>
          <Button variant="contained" color="primary" onClick={() => {
      const blogURL = window.location.hostname.includes("blog")
        ? process.env.REACT_APP_PUBLIC_URL
        : process.env.REACT_APP_BLOG_URL;
      
      window.location.href = blogURL; // Redirect to the determined URL
    }}>
          
          <FormattedMessage id="Visit Blog"  defaultMessage="Visit Blog" />
        </Button>
        </Grid>
        </Grid>
      </Container>

      {/* Leaderboard Section */}
      <Container className={classes.blogSection}>
      <Grid container className='justify-content-center' spacing={4}>
      <Grid item xs={11}>
        <Typography variant="h4" className={classes.sectionTitle}>
          <FormattedMessage id="Leaderboard" defaultMessage="Leaderboard" />
        </Typography>
        <Typography className={classes.contactDescription}>
          <FormattedMessage id="See where you rank among others. The more challenges you solve, the higher your ranking." defaultMessage="See where you rank among others. The more challenges you solve, the higher your ranking." />
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/leaderboard")}>
          
          <FormattedMessage id="Visit Leaderboard"  defaultMessage="Visit Leaderboard" />
        </Button>
        </Grid>
        </Grid>
      </Container>

      {/* Contact Section */}
      <Container className={classes.contactSection}>
      <Grid container className='justify-content-center' spacing={4}>
      <Grid item xs={11}>
        <Typography variant="h4" className={classes.sectionTitle}>
          <FormattedMessage id="contact-title" defaultMessage="Contact Us" />
        </Typography>
        <Typography className={classes.contactDescription}>
          <FormattedMessage id="contact-desc" defaultMessage="Have any questions or need support? Feel free to reach out via email!" />
        </Typography>
        <a href="mailto:georgek981@yahoo.com" className={classes.contactEmail}><FormattedMessage id="My email" defaultMessage="My email" /></a>
        </Grid>
        </Grid>
      </Container>

      {/* Footer Section */}
      <footer className={classes.footer}>
        <Typography variant="body2" className={classes.footerText}>
          <Box mt={2}>
            <Link component={`${process.env.REACT_APP_PUBLIC_URL}/privacy-policy`} to="privacy-policy" color="inherit" target="_blank" style={{ marginRight: '1rem' }}>
              <FormattedMessage
                id="privacy"
                defaultMessage="Privacy Policy"
              />
            </Link>
            <Link  component={`${process.env.REACT_APP_PUBLIC_URL}/cookies-policy`} to="cookies-policy" color="inherit" target="_blank">
              <FormattedMessage
                id="cookies"
                defaultMessage="Cookies Policy"
              />
            </Link>
          </Box>
          <Copyright />
        </Typography>
      </footer>
    </div>
    </>
  );
};

export default Home;
