// src/App.js
import React, { useContext, useEffect } from 'react';
import { Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import { CircularProgress, Paper } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { BankAuthContext, endSession } from '../../../../actions/challenge.js';
import ResetLevelButton from '../../../Button/ResetLevelButton/ResetLevelButton.js';
import { useDispatch, useSelector } from 'react-redux';
import OpenModal from '../../../Button/ResetLevelButton/OpenModal.js';
import ScenarioInstructionsModal from '../../../Modal/ScenarioInstructionsModal.js';
import { ModalContext } from '../../../../contexts/ModalContext.js';
import { getChallengeAnswers } from '../../../../actions/challenge.js';
import Dashboard from './Dashboard/Dashboard.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs,
    getRestart,
    getAllUrls, getBrowsers, getChallengeData, getScriptValid
  } from "selectors/browser";

const SocialProfileScenario = () => {

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const restart = useSelector(getRestart);
    const browsers = useSelector(getBrowsers);
    const challengeData = useSelector(getChallengeData);
    const allUrls = useSelector(getAllUrls);
    const scriptValid = useSelector(getScriptValid);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    
    const theme = useTheme();
    const classes = useStyles(theme);

    console.log(theme, "within iframe")

    const dispatch = useDispatch();
    
    // useEffect(() => {
    //     console.log('prior',url, session)
    //     if (session.status) {
    //         console.log('ηευυυυ',session)
    //     }
    // }, [url])

    // console.log(session, sessionTest, sessionTest.data[activeTab], activeTab)

    //' OR balance > 1000000

    return (
        <Container component="main" className="m-0 p-0" maxWidth="md">
            {page == null ? 
            <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
                <CircularProgress />
                
            </Container> : 
            <>
                {page === "login" ? <LoginPage title="Login to your {platform} account." platform="BestSocial" /> : <Dashboard />}
            </>}
        </Container>
    );
};

export default SocialProfileScenario;