import React from 'react';
import { Container, Button,Typography} from "@material-ui/core";

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateNextChallenge, hasProgress } from 'actions/challenge.js';
import { FormattedMessage } from 'react-intl';

const Instructions = () => { 

  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    
    // check if the user has made any progress
    // if so, navigate them to the experience quiz
    // else, navigate them to the first challenge

    dispatch(hasProgress()).then(data =>{

      console.log(data)

      if(data.hasProgress){
        dispatch(generateNextChallenge()).then(data =>{
          navigate(`/challenge/${data.seed}/description`);
      })
      }else{
        navigate("/intro-survey")
      }
    })
  };
  
    return (
      <Container  className="text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <Typography variant="h5" className={classes.text}>
          <FormattedMessage id="instructions" defaultMessage="Based on your experience, a challenge will be presented to you. Your goal is to follow the steps and complete the challenge. Don't worry. The first challenges are easy to go through. As you progress, HackMeDown will be assessing your behaviour and provide more difficult challenges. Hints and help will be provided by challenge, but you are strongly encouraged to work through the examples by yourself to enhance your learning." />
        </Typography>
        <Button variant="contained" color="primary" style={{margin: "1rem"}} onClick={() => handleNext()}>
          <FormattedMessage id="lets-go" defaultMessage="Let's Go"></FormattedMessage>
        </Button>
      </Container>
    );
  };
  

export default Instructions;
