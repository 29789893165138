import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { AuthContext } from "../../../actions/auth.js";
import useStyles from "../LogoutButton/styles.js";
import { resetLevel } from "../../../actions/challenge.js";
import { ChallengeDataContext } from "contexts/ChallengeData.js";

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs,
    getRestart
  } from 'selectors/browser';
import clearCookies from "helper.js";
import { FormattedMessage } from "react-intl";


const ResetLevelButton = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {user} = useContext(AuthContext);

    const {challenge} = useParams();

    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const restart = useSelector(getRestart);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const reset = () => {
        dispatch(resetLevel({username: user.result.username, currentChallenge: challenge})).then(data => {

            // reset all cookies containing browser and tab (such as browser_tab)
            clearCookies();

            dispatch({type: 'UPDATE_RESTART', payload: true});
            window.location.reload(false);
        });
    };

    return (
        <Button
            variant="contained"
            className="m-2"
            onClick={() => reset()}
            color="primary"
        >
            {" "}
            {<FormattedMessage id="Reset" defaultMessage="Reset" />}
        </Button>
    );
}

export default ResetLevelButton;