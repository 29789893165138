import { Avatar } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from "styled-components";
import { AuthContext } from '../../../actions/auth.js';
import useStyles from "./AvatarStyles.js";

const AvatarIcon = ({style, username, avatar}) => {

    const theme = useTheme();
    const classes = useStyles(theme);
    
    // const { user } = useContext(AuthContext);
    // const [userUsername, setUserUsername] = useState(username);

    // // if there is a logged in user, and the username is not specified, then use the logged in user info
    // useEffect(() => {
    //     if(user){
    //         if(!username){
    //             setUserUsername(user.result.username);
    //         }
    //     }
    // }, [user]);

    return(
        <>
            {avatar ? 
                (<Avatar className={style} alt={username} src={avatar}>W</Avatar>)
                : (
                    username ? (<Avatar className={[classes.purple, style]}>{username.charAt(0).toUpperCase()}</Avatar>)
                    : <Avatar className={style} src="/broken-image.jpg"></Avatar>
                )
            }
        </>
    )
}

export default AvatarIcon;