// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import { Container, CssBaseline } from '@mui/material';
import { Grid, Paper, Typography } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { fetchStolenCredentials, stolenCreditCardsCheck } from 'actions/phishingScenario.js';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from 'selectors/browser';
import { getParameters } from 'helper/helper.js';


const PhishingScenario = () => {

    
    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const theme = useTheme();
    const classes = useStyles(theme);
    const [error, setError] = useState(null);
    const [stolenPackets, setStolenPackets] = useState([]);
    const [iframeSrc, setIframeSrc] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {

        if(!activeTab) return;

        dispatch(fetchStolenCredentials()).then((data) => {
            if(data)
            {
                if(data.message){
                    setError(data.message)
                }
                else{
                    setStolenPackets(data.data);
                }
            }
            else{
                setError("Error fetching stolen cookies");
            }
        });

        if(activeTab == "stolencreditcards"){
            dispatch(stolenCreditCardsCheck()).then((data) => {
                console.log("checking stolen credit cards")
            });
        }

        const match = /auth=([^;]+)/.exec(document.cookie);
        const authValue = match ? match[1] : null;
        console.log(authValue);
        console.log(activeTab, authValue, "active tab and auth value");
        setIframeSrc(`${process.env.REACT_APP_PHISHING_URL || 'http://localhost:5004'}?auth=${encodeURIComponent(authValue)}&dbname=${activeTab}`)

    },[activeTab]);

    //' OR balance > 1000000

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>
                {/* <h2>All Cookies</h2>
                <Typography>Cookies I stole so far...</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Origin</Typography>
                        {stolenCookies.map((cookie, index) => (
                            <Typography key={index}>{cookie.origin}</Typography>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Cookie</Typography>
                        {stolenCookies.map((cookie, index) => (
                            <Typography key={index}>{cookie.cookie}</Typography>
                        ))}
                    </Grid>
                </Grid>
                {error && <Typography>{error}</Typography>} */}
                {iframeSrc && 
                (<iframe
                    title="Phishing"
                    src={iframeSrc}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                />)}
            </Paper>
            {/* <OpenModal modal={"finish"} title="Finish"></OpenModal> */}
        </Container>
    );
};

export default PhishingScenario;