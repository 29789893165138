import { AUTH,AUTH_ERROR,LOGOUT,SEND_VERIFICATION_CODE,SET_CHALLENGE_STATUS,UPDATE_SETTINGS,VERIFY_CODE } from "../constants/actionTypes.js";
import AuthReducer from "../reducers/auth.js";
import * as api from "../api/index.js";
import { createContext, useEffect, useReducer  } from "react";

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    const {data} = await api.signup(formData);
    const action = { type: AUTH, data: data };

    console.log(data)

    if(data.message){
      dispatch({ type: AUTH_ERROR, data: data });
    }
    else{
      dispatch({ type: AUTH, data: data });
    }

    return data;

    // console.log(data)

    // console.log(data);

    
  } catch (error) {
    console.log(error.message, error);
  }
};

export const signin = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signin(formData);

    if(data.message){
      dispatch({ type: AUTH_ERROR, data: data });
    }
    else{
      dispatch({ type: AUTH, data: data });
    }

    // console.log(data)
    return data;

    // dispatch(action);
    
    // navigate("/")
  } catch (error) {
    console.log(error.message);
    dispatch(error.message);
  }
};
 
const USER = {
  user: localStorage.getItem("profile") !== undefined ? JSON.parse(localStorage.getItem("profile")) : null,
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const { data } = await api.forgotPassword(email);

    return data;
  } catch (error) {
    console.log(error.message);
  }

}

export const resetPassword = ({password, confirmPassword, token, userId}) => async (dispatch) => {
  try {
    const { data } = await api.resetPassword({password, confirmPassword, token, userId});

    return data;
  } catch (error) {
    console.log(error.message);
  }

}

export const AuthContext = createContext(USER);

export const AuthContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(AuthReducer, USER);
  
  // useEffect(()=>{
  //   console.log(state)
  // },[state.user])

  console.log("asdfasdf AUTH")

  
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};