import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { AuthContext } from "../../../actions/auth.js";
import useStyles from "./styles.js";
import { ChallengeDataContext } from "contexts/ChallengeData.js";
import { FormattedMessage } from "react-intl";


const LogoutButton = () => {

    const theme = useTheme();
    const classes = useStyles(theme);;
    const {user} = useContext(AuthContext)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch({ type: "LOGOUT" });

        document.cookie = `auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        // clear session_id from local storage too

        localStorage.removeItem('session_id');

        navigate("/login");
    };

    return (
        <Button
            variant="contained"
            className={classes.logout}
            onClick={logout}
            color="secondary"
        >
            {" "}
            <FormattedMessage id="logout" defaultMessage="Logout"></FormattedMessage>
        </Button>
    );
}

export default LogoutButton;