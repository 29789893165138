import { BANK_SUBMIT, BANK_SUBMIT_ERROR, BANK_TRANSFER_ERROR, BANK_TRANSFER, CREATE_BANK_TRANSACTION, GET_BALANCE, RESET_LEVEL, UPDATE_CHALLENGE_STATUS, LOGOUT, SET_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { createContext, useEffect, useReducer } from "react";

export const getBalance = (info) => async (dispatch) => {
  try{
    const {data} = await api.getBalance(info);

    // ' OR balance > 1000000 --
    dispatch({ type: GET_BALANCE, payload: data });

    if(data.step)
    {
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    



    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "BANK_TRANSFER_ERROR", payload: e.message });
  }
};

export const getTransfers = (info) => async (dispatch) => {
  try{
    const {data} = await api.getTransfers(info);

    // ' OR balance > 1000000 --
    if(data.message){
      dispatch({ type: BANK_TRANSFER_ERROR, payload: data });
      return {error: data.message};
    }
    else{
      dispatch({ type: BANK_TRANSFER, payload: data.transactions });
      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
    }
    return data.transactions;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "BANK_TRANSFER_ERROR", payload: e.message });
  }
};

export const transfer = (info) => async (dispatch) => {
  // try{
    const {data} = await api.bankTransfer(info);

    // ' OR balance > 1000000 --

    // console.log(data)

    if(data.message){
      dispatch({ type: BANK_TRANSFER_ERROR, payload: data.message });
      return {error: data.message};
    }
    else{
      dispatch({ type: CREATE_BANK_TRANSACTION, payload: data.transfer });
      if(data.step){
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
      return data;
    }

  // }
  //  catch (e) {
  //   console.log(e)
  //   dispatch({ type: BANK_TRANSFER_ERROR, payload: e.message });
  // }
};
