import React, { useContext, useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Container } from '@material-ui/core';
import { getUsers } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { DangrousElement } from 'Components/Challenge/Scenarios/ECommerceScenario/Dashboard/DangerousElement/DangerousElement';
import { xssScriptValidity } from 'actions/validScript';

import { useTheme } from "styled-components";
import useStyles from "../styles.js";

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs,
  getRestart,
  getAllUrls, getBrowsers, getChallengeData, getScriptValid
} from "selectors/browser";

const Users = () => {

    const [query, setQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const restart = useSelector(getRestart);
    const browsers = useSelector(getBrowsers);
    const allUrls = useSelector(getAllUrls);
    const scriptValid = useSelector(getScriptValid);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const dispatch = useDispatch();
    
    let challengeSeed = "";
    const regex = /challenge(\d+)/;
    let challengeRegex = window.location.href.match(regex);
    if(challengeRegex){
      challengeSeed = challengeRegex[0];
    }

    useEffect(() => {

        if(!params.query) return;

        if(params.query){
            setQuery(params.query)
        }

        dispatch(getUsers({query: params.query})).then(results => {
        console.log(params.query, "query", results.error)
        console.log(scriptValid)
            
            if(results.error)
            {
              setUsers([results.error]); // Update the search results
              setError(results.error);
            }
            else{
              setUsers(results);
              setError(null)
            }
            // console.log(results)
    
          })
          .catch((error) => {
            setError(error);
          });

    },[params.query])

    useEffect(() => {
      console.log("scriptValid", scriptValid, query)
      // if(!scriptValid) return;

      // move to the next step
      if(scriptValid && query){
        dispatch(xssScriptValidity({isValid: scriptValid, script: query})).then(results => {
          console.log(results)
          dispatch({type: "UPDATE_SCRIPT_VALID", payload: false});

        })
      }

    },[scriptValid]);

    const handleUserClick = (result) => {
        // Replace this with the function you want to call when a user is clicked
        // set url to user profile
        dispatch({type: "UPDATE_URL", payload: domain+`/profile?uid=${result.username}`});
        dispatch({type: "UPDATE_PAGE", payload: "profile"});
        dispatch({type: "UPDATE_USER", payload: result});
        // console.log(`User clicked: ${result}`);
      };

    const challengeData = useSelector(state => state.challenge);

    console.log(challengeData.data, challengeSeed, window.location.href, "asd ffjadklf af alfafjsfjda;")

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" className={classes.text} gutterBottom>
      {challengeSeed === "challenge11" || challengeSeed === "challenge13" ? 
        <DangrousElement markup={`Users matching with '${decodeURIComponent(query)}'`} />
        : `Users matching with '${query}'`
      }
      </Typography>
      <List>
        {error ? 
            <>
                {(challengeSeed !== "challenge11" && challengeSeed !== "challenge13" ? 
                    error
                    : <DangrousElement markup={decodeURIComponent(error)} />
                )}
            </>
            :
            <></>
        }
        {users.map(result => (
          <div key={result.id} style={{ display: 'flex', alignItems: 'start', padding: "0.25rem" }}>
          <div
            onClick={() => handleUserClick(result)}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              // backgroundColor: '#f0f0f0', // Background color for the "button"
              cursor: 'pointer', // Cursor style to indicate it's clickable
              width: "100%"
            }}
          >
          <Avatar src={result.avatar} alt={result.name} />
            <div className={classes.text} style={{ marginLeft: 16, display: 'flex', alignItems: 'start', flexDirection: "column" }}>
              <Typography variant="body1">{result.name}</Typography>
              <Typography variant="body2">@{result.username}</Typography>
            </div>
          </div>
        </div>
        ))}
      </List>
    </Container>
  );
}

export default Users;
