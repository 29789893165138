import { makeStyles } from "@material-ui/core/styles/index.js";
import { deepPurple } from "@material-ui/core/colors/index.js";

export default makeStyles((them) => ({
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  heading: (theme) => ({
    color: theme.logo,
    textDecoration: "none",
  }),
  text: (theme) => ({
    color: theme.textColorSoft
  }),
}));
