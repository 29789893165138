import { GET_BALANCE} from "../constants/actionTypes.js";

const initialState = {
  data: null,
  loading: false,
  error: null,
}

export default (bankBalance = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE:
      if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }  
      return {data: action?.payload, loading: false, error: null};
    default:
      return bankBalance;
  }
};
