import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
  container: (theme) => ({
    textAlign: 'center',
    marginTop: "1rem",
  }),
  slider: (theme) => ({
    width: '80%',
    margin: 'auto',
    marginTop: "0.5rem",
  }),
  submitButton: (theme) => ({
    marginTop: "0.5rem",
  }),
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  heading: (theme) => ({
    color: theme.logo,
    textDecoration: "none",
  }),
  text: (theme) => ({
    color: theme.textColorSoft
  }),
  radioGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabel: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 20%',
  },
  radio: (theme) => ({
    color: theme.textColor, // Use theme color for radio button
    '&.Mui-checked': {
      color: theme.textColor, // Use primary color when checked
    },
    '&:hover': {
      backgroundColor: theme.bg, // Hover background color
    },
    '&.MuiRadio-root': {
      color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.54)" : "rgba(0, 0, 0, 0.54)", // Use theme color for radio button
    }
  }),
}));