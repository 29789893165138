import React, { useEffect, useState } from 'react';
import { fetchArticle } from 'actions/blog';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify
import {
  Typography,
  Container,
  Breadcrumbs,
  Link as MuiLink,
  Grid,
  Button,
} from '@material-ui/core';

import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import useStyles from "./styles.js";
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';

const BlogPost = () => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [post, setPost] = useState([]);
  const [error, setError] = useState(null);
  
  const { category, slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    console.log(category, slug)
    dispatch(fetchArticle(category, slug)).then((result) => {
      console.log(result);
      if(!result){
        setError("An uknown error occurred while fetching this article.");
      }
      else{
        if (result.error) {
          setError(result.error);
        } else {

          result.content = result.content.replace(/<h1/g, `<h1 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h1>/g, '</h1>')
        .replace(/<h2/g, `<h2 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h2>/g, '</h2>')
        .replace(/<h3/g, `<h3 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h3>/g, '</h3>')
        .replace(/<h4/g, `<h4 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h4>/g, '</h4>')
        .replace(/<h5/g, `<h5 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h5>/g, '</h5>')
        .replace(/<h6/g, `<h6 class="${classes.headings} ${classes.txtSoft}"`).replace(/<\/h6>/g, '</h6>')
        .replace(/<pre/g, `<pre class="${classes.bg} ${classes.txt}"`).replace(/<\/pre>/g, '</pre>');

          setPost(result);
        }
      }
    });

  }, [])

  useEffect(() => {
    if (post && post.content) {

      // for post.content, update all tags which are h1, h2, h3, h4, h5, h6 and add the class classes.txt to them
      

      // Get all elements with class 'spoil-answer'
        const spoilAnswers = document.querySelectorAll('.spoil-answer');

        // Iterate over each spoil-answer element
        spoilAnswers.forEach(spoilAnswer => {
            // Add click event listener
            spoilAnswer.addEventListener('click', function() {
                // Get the next sibling element with class 'solution'
                const solution = document.getElementById(this.dataset.solution);
                
                // Toggle the display of the solution
                if (window.getComputedStyle(solution).getPropertyValue("display") === 'none') {
                    solution.style.display = 'block';
                } else {
                    solution.style.display = 'none';
                }
            });
        });
    }
  
  }, [post]);

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        <link rel="canonical" href={`/${category}/${slug}`} />
      </Helmet>
      <Container className={classes.root}>
        <Grid xs className='mb-3'  >

        <Breadcrumbs aria-label="breadcrumb" className={`${classes.breadcrumbs} ${classes.txt}`}>
          <MuiLink component={Link} to="/" color="inherit">
            <FormattedMessage id="home" defaultMessage="Home" ></FormattedMessage>
          </MuiLink>
          <MuiLink component={Link} to={`/${category}`} color="inherit">
            {category}
          </MuiLink>
          <Typography>{slug}</Typography>
        </Breadcrumbs>
        </Grid>

        {post ? (
          <div className={classes.txt}>
            <Typography variant="h3" gutterBottom className={classes.title}>
              {post.title}
            </Typography>
            <Typography variant="body1" paragraph className={classes.content}>
              {/* Render sanitized HTML content */}
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.href = `${process.env.REACT_APP_PUBLIC_URL || "https://george.tardis.ac"}/challenge/${post.challenge.challengeSeed}/description`}
            >
              <FormattedMessage id="try-example" defaultMessage="Try this example in the simulated environment" ></FormattedMessage>
            </Button>
          </div>
        ) : (
          <Typography variant="h5" className={classes.loading}>
            Loading...
            <FormattedMessage id="loading" defaultMessage="Loading..." ></FormattedMessage>
          </Typography>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.error} align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default BlogPost;