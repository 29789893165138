import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  CssBaseline,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@material-ui/core';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getTopUsers} from '../../actions/challenge.js';
import { useTheme } from "styled-components";

import useStyles from './styles';
import { FormattedMessage } from 'react-intl';
import Flag from 'react-world-flags'; // Import the Flag component
import { getName } from 'country-list'; // Import getName function to get country names

const Leaderboard = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.leaderboard);

  // Function to fetch leaderboard data from backend
  const fetchLeaderboardData = async () => {

    console.log(users.data, "adfdadfs");
    if(!users.data) return;

    try{

      setError(null);
      if(users.data.length > 0){
          console.log(users.data);
          setLeaderboard(users.data);
          setLoading(false);
      }
      else{
        // Replace with your backend API endpoint
      
        dispatch(getTopUsers()).then(data => {
          console.log(data);
          if(data)
            {
            if(data.message){
              setError(data.message);
            }
            else{
              setLeaderboard(data.users);
              console.log(data.users)
              setError(null);
            }
          }
          else{
            setError('Failed to fetch leaderboard data');
          }
        setLoading(false);
        });
      }
      
      // Assuming the response contains a data array of users who completed all challenges
    } catch (err) {
      setError('Failed to fetch leaderboard data');
      setLoading(false);
    }
  };

  // Fetch leaderboard data on component mount
  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  return (
    <Container className={classes.container} maxWidth="md">
      <CssBaseline />
      <Typography variant="h4" className={classes.title}>
        <Typography variant="h3" className={classes.text}>
          <FormattedMessage id="Leaderboard" defaultMessage="Leaderboard" />
        </Typography>
      </Typography>

      {/* Show loading spinner while data is being fetched */}
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : error ? (
        // Display error message if data fetching fails
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      ) : (
        // Display leaderboard data in a table
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}><FormattedMessage id="Rank" defaultMessage="Rank" /></TableCell>
                <TableCell className={classes.headerCell}><FormattedMessage id="Player Name" defaultMessage="Player Name" /></TableCell>
                <TableCell className={classes.headerCell}><FormattedMessage id="Challenges Completed" defaultMessage="Challenges Completed" /></TableCell>
                <TableCell className={classes.headerCell}><FormattedMessage id="Region" defaultMessage="Region" /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <FormattedMessage id="No one has completed all challenges yet. Can you be the first one?" defaultMessage="No one has completed all challenges yet. Can you be the first one?" />
                  </TableCell>
                </TableRow>
              )}
              {leaderboard.map((player, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{player.userName}</TableCell>
                  <TableCell>{player.challengesCompleted}</TableCell>
                  {player.region && (
                  <TableCell>
                    {/* Display the flag and region name */}
                    <Flag className={classes.flag} code={player.region.toUpperCase()} alt={player.region} />
                    {/* {getName(player.region.toUpperCase()) || 'Unknown'} */}
                  </TableCell>
                  )}
                  {!player.region && <TableCell><FormattedMessage id="Unknown" defaultMessage="Unknown" /></TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default Leaderboard;
