import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { FormattedMessage } from 'react-intl';

const ScenarioProgress = (props) => {

  const {setStepNumber} = props;
  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content} = props;
 
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  return (
    <div>
        <Modal
            open={isOpen === "progress"}
            onClose={handleCloseModal} 
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>
                    <FormattedMessage id="Scenario Progress" defaultMessage="Scenario Progress" />
                </h2>
                <Box className={`${classes.textContent} text-start flex-column w-100`} style={{ display: 'flex', flexDirection: 'column' }}>
                    {content && Object.keys(content).map((key) => (
                        <>
                            {content[key].description ? (
                                <div key={key} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '8px' }}>
                                    {/* Icon Section */}
                                    <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                                        {content[key].isComplete == 1 ? (
                                            <CheckIcon style={{ color: 'green' }} />
                                        ) : (
                                            <CloseIcon style={{ color: 'red' }} />
                                        )}
                                    </div>
                                    {/* Text Section */}
                                    <div style={{ fontSize: '16px', wordBreak: 'break-word', flex: 1 }}>
                                        {content[key].description.charAt(0).toUpperCase() + content[key].description.slice(1)}
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ))}
                </Box>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    <FormattedMessage id="Got it" defaultMessage="Got it" />
                </Button>
            </Box>

        </Modal>
    </div>
  );
};

export default ScenarioProgress;