import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Button, CircularProgress } from '@material-ui/core';

import { useTheme } from "styled-components";

import useStyles from "../styles.js";
import { useDispatch, useSelector } from 'react-redux';
import { BankAuthContext, getTransfers } from '../../../../../../actions/bankScenario.js';
import SearchBar from '../Button/SearchBar.js';
import { SolutionsContext } from '../../../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { FormattedMessage } from 'react-intl';

const Transactions = (props) => {
  // Replace this with actual transaction data
  const [transactions, setTransactions] = useState([]);
  const [query, setQuery] = useState('');
  const [transactionsError, setTransactionsError] = useState(null);

  const dispatch = useDispatch();
  const bankUser = useSelector((state) => state.bank);
  
  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);
  
  const theme = useTheme();
  const classes = useStyles(theme);

  const {solutions, setSolutions} = useContext(SolutionsContext);

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {

    if (!session.user) return;

    const unionAnswer = solutions.union;
    if(unionAnswer){
      setQuery(unionAnswer ? (unionAnswer.query || "") : "");
    }

    setIsLoading(true);
    
    dispatch(getTransfers({username: session.user.username})).then(data => {
      if(data){
        // console.log(data)
        setIsLoading(false);
        if(!data.error){
          setTransactions(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        }
        else{
          setTransactionsError(data.error);
          setTransactions([]);
          console.log(data.error)
        }
      }
    })
  }, [solutions]);

  const handleSearch = (searchTerm) => {
    setIsLoading(true);
    console.log('Searching for:', searchTerm);
    dispatch(getTransfers({username: session.user.username, query: searchTerm})).then(data => {
      setTransactionsError(null)
      if(data){
        setIsLoading(false);
        if(!data.error){
          setTransactions(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        }
        else{
          setTransactionsError(data.error);
          setTransactions([]);
          console.log(data.error)
        }
      }
    })
    // Perform your search logic here
  };

  return (
    <Container maxWidth="md">
      <div  className={classes.text}>

      <Typography variant="h4" align="center" style={{ margin: '20px' }}>
        <FormattedMessage id="Transactions History" defaultMessage="Transactions History" ></FormattedMessage>
      </Typography>
      <SearchBar onSearch={handleSearch} query={query} />
            {isLoading 
            ? <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
                <CircularProgress />
              </Container> 
            : <>
              {transactions.length == 0 ? 
                <div className='w-100 text-center'>
                  <Typography variant="p" style={{ }}><FormattedMessage id="No transactions found" defaultMessage="No transactions found" ></FormattedMessage></Typography>
                </div>
              :
              <>
                  <TableContainer component={Paper}>
                  <Table className={classes.bg}>
                    <TableHead>
                      <TableRow >
                        <TableCell><Typography className={classes.text}><FormattedMessage id="Date" defaultMessage="Date" ></FormattedMessage></Typography></TableCell>
                        <TableCell><Typography className={classes.text}><FormattedMessage id="Description" defaultMessage="Description" ></FormattedMessage></Typography></TableCell>
                        <TableCell><Typography className={classes.text}><FormattedMessage id="Amount (GBP)" defaultMessage="Amount (GBP)" ></FormattedMessage></Typography></TableCell>
                        <TableCell><Typography className={classes.text}><FormattedMessage id="Action" defaultMessage="Action" ></FormattedMessage></Typography></TableCell>
                        <TableCell><Typography className={classes.text}><FormattedMessage id="Recipient" defaultMessage="Recipient" ></FormattedMessage></Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions.map((transaction) => (
                        <TableRow key={transaction.id}>
                          <TableCell><Typography className={classes.text}>{new Date(transaction.createdAt).toString() !== "Invalid Date" ? new Date(transaction.createdAt).toLocaleString() : transaction.createdAt}</Typography></TableCell>
                          <TableCell><Typography className={classes.text}>{transaction.description}</Typography></TableCell>
                          <TableCell><Typography className={classes.text}>{!isNaN(parseFloat(transaction.amount)) && Number(transaction.amount).toFixed(2)}<FormattedMessage id="£" defaultMessage="£" ></FormattedMessage></Typography></TableCell>
                          <TableCell><Typography className={classes.text}><FormattedMessage id={transaction.from == session.user.accountNumber ? "Sent" : "Received"} defaultMessage={transaction.from == session.user.accountNumber ? "Sent" : "Received"} ></FormattedMessage></Typography></TableCell>
                          <TableCell><Typography className={classes.text}>
                            {transaction.from == session.user.accountNumber ?
                              <FormattedMessage id="to" defaultMessage="to" ></FormattedMessage>
                            :
                            <FormattedMessage id="from" defaultMessage="from" ></FormattedMessage>
                            }
                            {transaction.from == session.user.accountNumber ? " "+transaction.sentTo : " "+transaction.sentFrom}
                            </Typography></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
              }
            </>  
          }
          

      {transactionsError && (
        <div className="w-100 pt-3 text-center">
          <Typography><FormattedMessage id={transactionsError} defaultMessage={transactionsError} ></FormattedMessage></Typography>
        </div>
      )}
      </div>
      <div className="w-100 d-flex justify-content-end">
        <Button variant="contained" className="my-3" color="primary" onClick={() => dispatch({type: "UPDATE_URL", payload:"https://www.bank.com"})}>
        <FormattedMessage id="back" defaultMessage="Back" ></FormattedMessage>
        </Button>
      </div>

{/* ' AND 1=2 UNION SELECT COLUMN_NAME, 1, 2, 3, 4 FROM information_schema.columns WHERE TABLE_NAME = 'users' --  */}
    </Container>
  );
};

export default Transactions;