import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import useStyles from './styles'; // Import styles from style.js
import { FormattedMessage } from 'react-intl';

const CustomTabs = ({ browsers, selectedBrowser, handleChangeBrowser }) => {
  const theme = useTheme(); // Use styled-components theme
  const classes = useStyles(theme); // Pass theme to useStyles

  const [activeTab, setActiveTab] = useState(selectedBrowser || browsers[0]);
  const [underlineStyle, setUnderlineStyle] = useState({ width: 0, left: 0 });
  const tabsRef = useRef([]); // To store references to each tab

  // Handle tab click
  const handleTabClick = (tab, index) => {
    setActiveTab(tab);
    handleChangeBrowser(tab);
    moveUnderline(index);
  };

  // Move underline to the position of the new active tab
  const moveUnderline = (index) => {
    if (tabsRef.current[index]) {
      const { offsetLeft, clientWidth } = tabsRef.current[index];
      setUnderlineStyle({ left: offsetLeft, width: clientWidth });
    }
  };

  // Set the initial position and width of the underline
  useEffect(() => {
    const index = browsers.indexOf(activeTab);
    moveUnderline(index);
  }, [activeTab, browsers]);

  useEffect(() => {
    if (!selectedBrowser) return;
    setActiveTab(selectedBrowser);
  }, [selectedBrowser]);

  return (
    <div className={classes.customTabsContainer}>
      {/* Loop through browsers and create each tab */}
      {browsers.map((tab, index) => (
        <div
          key={index}
          ref={(el) => (tabsRef.current[index] = el)} // Assign reference to each tab
          className={`${classes.customTab} ${activeTab === tab ? classes.activeTab : ''}`}
          onClick={() => handleTabClick(tab, index)}
        >
          {<FormattedMessage id={tab} defaultMessage={tab} ></FormattedMessage>}
        </div>
      ))}

      {/* Moving underline */}
      <div
        className={classes.movingUnderline}
        style={{
          width: underlineStyle.width,
          left: underlineStyle.left,
        }}
      ></div>
    </div>
  );
};

export default CustomTabs;
