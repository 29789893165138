//ForgotPassword.js

import { React, useState } from "react";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
    Card,
    CardContent
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/auth.js";

import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";
import { FormattedMessage } from "react-intl";

const ForgotPassword = () => {

    const dispatch = useDispatch();

      const theme = useTheme();
  const classes = useStyles(theme);

    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const email = data.get("email");

        console.log(email);

        dispatch(forgotPassword({email})).then(data => {
            console.log(data)
            try{
                if (data.success) {
                    toast.error(data.message, {
                        autoClose: 5000,
                        position: "top-right",
                    });
                    setMessage("Password link was sent to your email.");
                } else {
                    toast.success(data.message, {
                        autoClose: 5000,
                        position: "top-right",
                    });
                    setMessage("Password reset failed. Please try again.");
                }
            }
            catch(e){
                console.log(e)
                toast.success("An error occured. Please, try later", {
                    autoClose: 5000,
                    position: "top-right",
                });
            }
        })

    };
    return (
        <Container maxWidth="sm" className={classes.text}>
            <Box
                sx={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Card sx={{ boxShadow: "4" }}>
                    <CardContent sx={{ m: 3 }}>
                        <Avatar sx={{
                            m: "auto",
                            bgcolor: "primary.main"
                        }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1"
                            variant="h5" align="center" sx={{ mt: 1 }}>
                            <FormattedMessage id="Forgot Password" defaultMessage="Forgot Password" />
                        </Typography>

                        <Box component="form"
                            onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={<FormattedMessage id="Email Address" defaultMessage="Email Address" />}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                <FormattedMessage id="Reset Password" defaultMessage="Reset Password" />
                            </Button>
                        </Box>
                        {message && (

                        <Typography variant="body2" color="textSecondary" align="center">
                            <FormattedMessage id={message} defaultMessage={message} />
                        </Typography>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default ForgotPassword;