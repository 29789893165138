import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
    text: (theme) => ({
        color: theme.textColor
    }),
    textSoft: (theme) => ({
        color: theme.textColorSoft
    }),
    bgSoft: (theme) => ({
        backgroundColor: theme.bgSoft,
    }),
    bg: (theme) => ({
        backgroundColor: theme.bg,
    })
}));
