import React, { useContext, useEffect, useState } from 'react';
import { Container, Button,Typography, CssBaseline, Paper, Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CardActions, Card, CardContent} from "@material-ui/core";

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAllChallenges } from '../../../actions/challenge.js';
import challenge from 'reducers/challenge.js';
import { FormattedMessage } from 'react-intl';

const AllChallenges = () => { 

    const theme = useTheme();
    const classes = useStyles(theme);

    const [challenges, setChallenges] = useState([]);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

      // Function to open the modal and show challenge description
    const handleOpenModal = (challenge) => {
        setSelectedChallenge(challenge);
        setOpen(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setOpen(false);
        setSelectedChallenge(null);
    };

    const allChallenges = useSelector(state => state.allChallenges);

    useEffect(() => {
        console.log(allChallenges.data, "adfdadfs");
        if(!allChallenges.data) return;

        setError(null);
        if(allChallenges.data.length > 0){
            console.log(allChallenges.data);
            setChallenges(allChallenges.data);
        }
        else{
            dispatch(getAllChallenges()).then(data => {
                console.log(data, "hi there");
                if(data.meessage){
                    setError(data.message);
                    challenges([]);
                }
                else{
                    if(data.length === 0){
                        setError("No challenges found.");
                    }
                    else{
                        setError(null);
                        setChallenges(data);
                    }
                }
            });
        }
    }, []);

    return (
        <>
            <Container className={classes.container} maxWidth="md">
      <CssBaseline />
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="All Challenges" defaultMessage="All Challenges" />
      </Typography>
      <Grid container spacing={3}>
        {error && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h5" className={classes.challengeTitle}>
                <FormattedMessage id={error} defaultMessage={error} />
              </Typography>
            </Paper>
          </Grid>
        )}
        {!challenges.message &&
          challenges.map((challenge, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" className={classes.challengeTitle}>
                    {challenge.title}
                  </Typography>
                  {/* <Typography variant="body1" className={classes.challengeDescription}>
                    {challenge.description.substring(0, 100)}...
                  </Typography> */}
                </CardContent>
                {/* if the challenge is completed then add a tick */}
                {challenge.isComplete && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                      <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                      <Typography variant="h6" className={`${classes.completedText} ${classes.text}`}>
                        <FormattedMessage id="Completed" defaultMessage="Completed" />
                      </Typography>
                    </div>
                  )}
                <CardActions className='justify-content-center'>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleOpenModal(challenge)}
                  >
                    <FormattedMessage id="More" defaultMessage="More" />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => navigate(`/challenge/${challenge.challengeSeed}/description`)}
                  >
                    <FormattedMessage id="Open" defaultMessage="Open" />
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>

      {/* Modal for viewing challenge description */}
      {selectedChallenge && (
        <Dialog open={open} onClose={handleCloseModal}  aria-labelledby="challenge-dialog-title">
            <div className={`${classes.bg} ${classes.text}`}>

          <DialogTitle id="challenge-dialog-title">{selectedChallenge.title}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.text}>
              {selectedChallenge.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Close
            </Button>
          </DialogActions>
            </div>
        </Dialog>
      )}
    </Container>
        </>
    );

}

export default AllChallenges;