export const  getParameters = async (urlString) => {
    try {
        
        const extractParameters = (urlString) => {
            const parametersObject = {};
          
            // Extract the query string part of the URL
            const queryString = urlString.split('?')[1];
          
            if (queryString) {
              // Split the query string into key-value pairs
              const keyValuePairs = queryString.split('&');
          
              // Iterate through each key-value pair
              keyValuePairs.forEach((pair) => {
                  const [key, value] = pair.split(/=(.*)/);
                if (key && value) {
                  // Decode and store the key-value pair
                  parametersObject[key] = value.replace(/\+/g, ' ');
                }
              });
            }
          
            return parametersObject;
        };

        const parametersObject = extractParameters(urlString);

        return parametersObject;
    } catch (error) {
        console.error('Error extracting parameters:', error);
        return {}; // Return an empty object in case of an error
    }
};

// Function to format date in a human-readable format (e.g., "September 29, 2023") 
export const formatDate = async (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

export const isValidUrl = urlString=> {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
'((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
'(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
'(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
}