import { GET_EMAILS, GET_EMAILS_ERROR, SEND_EMAIL, SEND_EMAIL_ERROR } from "../constants/actionTypes.js";

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export default (emails = initialState, action) => {
    switch (action.type) {
        case GET_EMAILS:
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: action?.payload, loading: false, error: null};
        case SEND_EMAIL:
            console.log(action?.payload);
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: [...emails.data, action?.payload], loading: false, error: null};
        case SEND_EMAIL_ERROR:
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: emails.data, loading: false, error: action?.payload};
        case GET_EMAILS_ERROR:
            if(window.parent !== window){
                window.parent.postMessage({state: action}, "*");
            }
            return {data: [], loading: false, error: action?.payload};
        default:
            return emails;
    }
}